import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { activeMenuList } from '../MenuItemForm/filterFunctions';
import { filteredSideItems } from '../MenuItemForm/filterFunctions';

import { MEALS_MISSING, TERMS } from '../../../constants/notification-messages';
import { handleNotificationAction } from '../../../store/notification/notification.action';
import {
  setSelectedOrderDate,
  clearCurrentOrder,
} from '../../../store/orders/orders.actions';
import {
  selectedMenuItem,
  selectedSideMenuItem,
  selectedOrderDate,
  selectedExtraMenuItems,
  shouldSendEmail,
} from '../../../store/orders/orders.selector';
import { createOrderAsync } from '../../../store/orders/orders.thunk';
import {
  restaurantsForDate,
  menuItems,
  selectedRestaurant,
  termsAndConditionsStatus,
} from '../../../store/restaurants/restaurants.selector';
import {
  getCategoriesAsync,
  getMenuItemsAsync,
  getRestaurantAsync,
  getRestaurantsForDateAsync,
  getTermsAndConditionsStatusAsync,
} from '../../../store/restaurants/restaurants.thunk';
import { handleDateFormatting } from '../../../utils/orderHelperFunctions';
import Button from '../../atoms/Button/Button';
import { IconAlert } from '../../atoms/Icons';
import Form from '../../molecules/Form/Form';

import FinalStep from './FinalStep';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
// import Step5 from './Step5';
import Step6 from './Step6';
import './order.scss';

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allItems = activeMenuList(useSelector(menuItems));
  const orderDate = useSelector(selectedOrderDate);
  const restaurant = useSelector(selectedRestaurant);
  const orderMainMenuItem = useSelector(selectedMenuItem);
  const orderSideMenuItem = useSelector(selectedSideMenuItem);
  const orderExtraMenuItems = useSelector(selectedExtraMenuItems);
  const termsAndConditionsAccepted = useSelector(termsAndConditionsStatus);
  const sendEmail = useSelector(shouldSendEmail);
  const allRestaurants = useSelector(restaurantsForDate);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [currentDate, setCurrentDate] = useState(
    orderDate ? orderDate : tomorrow
  );
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentOrder()());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (allRestaurants.length) {
      const firstRestaurantId = allRestaurants[0]?.id;
      dispatch(getRestaurantAsync(firstRestaurantId));
      dispatch(getTermsAndConditionsStatusAsync(firstRestaurantId));
    }
  }, [allRestaurants]); // eslint-disable-line

  useEffect(() => {
    if (step === 3) {
      dispatch(getCategoriesAsync());
    }
  }, [step]); // eslint-disable-line

  useEffect(() => {
    const date = currentDate.toString().slice(0, 15);
    dispatch(getRestaurantsForDateAsync(date));
    dispatch(setSelectedOrderDate(date)());
  }, [currentDate]); // eslint-disable-line

  useEffect(() => {
    if (restaurant) {
      dispatch(getMenuItemsAsync(restaurant.id, restaurant.menuId));
    }
  }, [restaurant]); // eslint-disable-line
  let content;

  const nextHandler = () => {
    if (step === 2 && !termsAndConditionsAccepted) {
      setShowTermsModal(true);
      dispatch(handleNotificationAction({ title: TERMS, status: 'error' })());
      return;
    } else if (step === 3 && !orderMainMenuItem) {
      dispatch(
        handleNotificationAction({
          title: MEALS_MISSING.MAIN,
          status: 'error',
        })()
      );
      return;
    } else if (
      step === 3 &&
      filteredSideItems(allItems, [orderMainMenuItem]).length === 0
    ) {
      // skipping side item step if there are no side items
      setStep(step + 2);
      return;
    }
    setStep(step + 1);
  };

  const backHandler = () => {
    if (
      step === 5 &&
      filteredSideItems(allItems, [orderMainMenuItem]).length === 0
    ) {
      setStep(step - 2);
      return;
    }
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      content = (
        <Step1 currentDate={currentDate} setCurrentDate={setCurrentDate} />
      );
      break;
    case 2:
      content = (
        <Step2
          allRestaurants={allRestaurants}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
        />
      );
      break;
    case 3:
      content = <Step3 allItems={allItems} orderDate={orderDate} />;
      break;
    case 4:
      content = (
        <Step4 allItems={allItems} orderMainMenuItem={orderMainMenuItem} />
      );
      break;
    // case 5:
    //   content = <Step5 allItems={allItems} orderDate={orderDate} />;
    //   break;
    // TODO: uncomment this line on V2 when extra items are added
    case 5:
      content = <Step6 />;
      break;
    case 6:
      content = <FinalStep />;
      break;
    default:
      content = null;
      break;
  }

  const placeOrder = (data) => {
    let comment = data.comment;
    if (data.deliveryLocation) {
      if (data.deliveryLocation === 'office') {
        comment = 'Ke jadam vo kancelarijata. ' + comment;
      } else if (data.deliveryLocation === 'restaurant') {
        comment = 'Ke jadam vo restoranot. ' + comment;
      }
    }
    const orderData = {
      restaurantId: restaurant.id,
      locationId: Number(data.locationId),
      menuId: restaurant.menuId,
      shouldSendEmail: sendEmail,
      comment: comment,
      mainMenuItemId: orderMainMenuItem.menuItemId,
      sideMenuItemId: orderSideMenuItem?.menuItemId,
      extraMenuItemIdList: orderExtraMenuItems.map((item) => item.menuItemId),
      date: handleDateFormatting(currentDate.toLocaleDateString('en-GB')),
    };

    dispatch(createOrderAsync(orderData, navigate));
  };

  return (
    <div className="p-order">
      <Form id="order" className="p-order__form" onSubmit={placeOrder}>
        {content}
      </Form>
      {!allRestaurants.length && (
        <div className="p-order__alert">
          <IconAlert />
          <p>
            There are no available restaurants for this date. Please choose
            another date.
          </p>
        </div>
      )}
      <div className="p-order__button-group">
        <Button
          classes={`square ${step === 1 ? 'hidden' : ''}`}
          text="back"
          onClick={backHandler}
        />
        {step !== 6 && (
          <Button
            disabled={!allRestaurants.length}
            classes="square"
            text="next"
            onClick={nextHandler}
          />
        )}
        {step === 6 && (
          <Button classes="square" text="order" type="submit" form="order" />
        )}
      </div>
    </div>
  );
};

export default Order;
