import { useFormContext } from 'react-hook-form';

import { IconAlert } from '../Icons';

import './input.scss';

const Input = ({
  className,
  label,
  name,
  id,
  icon,
  validation,
  dataTestId = 'input-element',
  value,
  ...rest
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onClick = () => {
    if (value) setValue(name, value);
  };

  return (
    <div className="a-input-field" data-testid="input-field-element">
      {label && (
        <label
          data-testid="label-element"
          htmlFor={id}
          className={`a-input-field__label a-input-field__label--${className}`}
        >
          {label}
        </label>
      )}

      <input
        {...register(name, {
          pattern: {
            value: validation?.pattern,
            message: validation?.patternMessage,
          },
          minLength: {
            value: validation?.minLength,
            message: validation?.minLengthMessage,
          },
          maxLength: {
            value: validation?.maxLength,
            message: validation?.maxLengthMessage,
          },
        })}
        data-testid={dataTestId}
        className={`a-input-field__input a-input-field__input--${className}`}
        id={id}
        name={name}
        onClick={onClick}
        aria-invalid={!!errors[name]}
        aria-errormessage="error-message"
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        required={rest.required}
        title={rest.title}
        readOnly={rest.readOnly}
        placeholder={rest.placeholder}
        type={rest.type}
        autoComplete={rest.autoComplete}
      />
      {icon}
      {errors[name] && (
        <p
          id="error-message"
          className="a-input-field__error-message"
          data-testid="error-message"
        >
          <IconAlert />
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default Input;
