const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.504"
    height="12.184"
    viewBox="0 0 8.504 12.184"
  >
    <path
      id="arrow-right"
      d="M-6.1-2.818V-.152l8.5-4.7V-7.612l-8.5-4.724v2.689L.047-6.232Z"
      transform="translate(6.096 12.336)"
    />
  </svg>
);

export default ArrowRight;
