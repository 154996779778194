import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { REGEX_INPUT } from '../../../constants/regex-patterns';
import { createNewUserAsync } from '../../../store/users/users.thunk';
import { companies } from '../../../utils/options';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Form from '../../molecules/Form/Form';

import './createNewUser.scss';

const CreateNewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const { firstName, lastName, email, cardId, company } = data;
    dispatch(createNewUserAsync(firstName, lastName, email, cardId, company));
  };

  return (
    <div className="row">
      <div className="form-layout col__desktop-6 col__laptop-6 col__tablet-12 col__mobile-12 ">
        <Form
          data-testid="create-new-user__form"
          onSubmit={handleSubmit}
          className="create-new-user-form"
          title="Create New User"
        >
          <div className="create-new-user-form__input-wrapper">
            <Input
              dataTestId="input-first-name"
              validation={{
                pattern: REGEX_INPUT.NAME,
                patternMessage: INVALID_INPUT.NAME,
                maxLength: 100,
                maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
              }}
              className="long-black"
              id="firstName"
              name="firstName"
              placeholder="first name"
              required
              type="text"
            />
            <Input
              dataTestId="input-last-name"
              validation={{
                pattern: REGEX_INPUT.NAME,
                patternMessage: INVALID_INPUT.NAME,
                maxLength: 100,
                maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
              }}
              className="long-black"
              id="lastName"
              name="lastName"
              placeholder="last name"
              required
              type="text"
            />
            <Input
              dataTestId="input-email"
              validation={{
                pattern: REGEX_INPUT.VALTECH_MAIL,
                patternMessage: INVALID_INPUT.VALTECH_MAIL,
              }}
              className="long-black"
              id="email"
              name="email"
              placeholder="email"
              required
              type="email"
            />
            <Input
              dataTestId="input-card-id"
              validation={{
                pattern: REGEX_INPUT.NUMBER,
                patternMessage: INVALID_INPUT.NUMBER,
                maxLength: 10,
                maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
              }}
              className="long-black"
              id="cardId"
              name="cardId"
              placeholder="card id"
              required
              type="text"
            />
            <SelectInput label="Entity" name="company" options={companies} />
          </div>
          <div className="create-new-user-form__button-wrapper">
            <Button
              testID="submit-button"
              type="submit"
              text="save"
              classes="bg--black hover-bg--green large"
            />
            <Button
              type="button"
              testID="cancel-button"
              onClick={() => navigate('/all-users')}
              icon="close"
              hoverIcon="close"
              classes="bg--black hover-bg--red small"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateNewUser;
