import UserItem from '../UserItem/UserItem';

import './userList.scss';

const UserList = ({ users }) => {
  const listOfUsers = users.map((user) => {
    return (
      <div
        key={user.id}
        className="col__desktop-3 col__laptop-4 col__tablet-6 col__mobile-12"
      >
        <UserItem
          firstName={user.firstName}
          lastName={user.lastName}
          username={`${user.firstName} ${user.lastName}`}
          email={user.email}
          company={user.company}
          alt={user.username}
          id={user.id}
          userStatus={user.userStatus}
          image={user.imageDto}
        />
      </div>
    );
  });

  return users.length ? (
    <div className="user-list">
      <div
        data-testid="user-list-element"
        className="row row__large mq-laptop-helper"
      >
        {listOfUsers}
      </div>
    </div>
  ) : (
    <h4 data-testid="no-users-heading">No Users</h4>
  );
};

export default UserList;
