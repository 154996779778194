export const orders = (state) => state.orders.orders;
export const ordersTotal = (state) => state.orders.total;
export const filteredOrders = (state) => state.orders.filteredOrders;
export const isLoading = (state) => state.orders.isLoading;
export const selectedOrder = (state) => state.orders.selectedOrder;
export const selectedOrderId = (state) => state.orders.selectedOrderId;
export const createdOrderSuccessfully = (state) =>
  state.orders.createdOrderSuccessfully;
export const ordersForDate = (state) => state.orders.ordersForDate;
export const selectedMenuItem = (state) => state.orders.selectedMenuItem;
export const selectedSideMenuItem = (state) =>
  state.orders.selectedSideMenuItem;
export const selectedExtraMenuItems = (state) =>
  state.orders.selectedExtraMenuItems;
export const currentOrderComment = (state) => state.orders.currentOrderComment;
export const allSelectedItems = (state) => state.orders.allSelectedItems;
export const selectedOrderDate = (state) => state.orders.selectedOrderDate;
export const shouldSendEmail = (state) => state.orders.shouldSendEmail;
export const currentUserOrders = (state) => state.orders.currentUserOrders;
