import React from 'react';

import RestaurantItem from '../RestaurantItem/RestaurantItem';

import './restaurantList.scss';

const RestaurantList = ({ restaurants }) => {
  const listOfRestaurants = restaurants?.map((restaurant) => {
    const { id, image, name, status } = restaurant;
    return (
      <div
        key={restaurant.id}
        className="col__desktop-3 col__laptop-4 col__tablet-6 col__mobile-12"
      >
        <RestaurantItem name={name} id={id} image={image} status={status} />
      </div>
    );
  });

  return restaurants.length ? (
    <div
      className="m-restaurant-list row row__large"
      data-testid="restaurant-list-element"
    >
      {listOfRestaurants}
    </div>
  ) : (
    <h4 data-testid="no-restaurant-heading">No Restaurants</h4>
  );
};

export default RestaurantList;
