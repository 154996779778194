import { useSelector } from 'react-redux';

import {
  isLoading as userIsLoading,
  isRegisteredSuccessfully,
} from './currentUser/currentUser.selector';
import {
  isCreatedMenuItemSuccessfully,
  isLoading,
} from './restaurants/restaurants.selector';
import {
  allUsersLoading,
  isCreatedUserSuccessfully,
} from './users/users.selector';

export const Loading = () => {
  const currentUserLoading = useSelector(userIsLoading);
  const usersLoading = useSelector(allUsersLoading);
  const restaurantsLoading = useSelector(isLoading);
  return currentUserLoading || usersLoading || restaurantsLoading;
};

export const ResetForm = () => {
  const successfullyCreatedUser = useSelector(isCreatedUserSuccessfully);
  const successfullyRegistered = useSelector(isRegisteredSuccessfully);
  const successfullyCreatedMenuItem = useSelector(
    isCreatedMenuItemSuccessfully
  );
  return (
    successfullyRegistered ||
    successfullyCreatedUser ||
    successfullyCreatedMenuItem
  );
};
