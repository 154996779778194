import instance from './setupAxios';

export const getAllRestaurants = async () => {
  return await instance.get('restaurant');
};

const createRestaurant = async (
  name,
  email,
  phonenumber,
  termsAndConditions,
  costForEmployees,
  maxOrdersPerDay,
  dailyCancelationTime,
  dailyOrderTime,
  orderSendingTime,
  requestedDeliveryTime,
  workingDays,
  restaurantLocations
) => {
  return await instance.post(`restaurant`, {
    name,
    email,
    phonenumber,
    termsAndConditions,
    costForEmployees,
    maxOrdersPerDay,
    dailyCancelationTime,
    dailyOrderTime,
    orderSendingTime,
    requestedDeliveryTime,
    workingDays,
    restaurantLocations,
    status: 'ACTIVE',
  });
};

const editRestaurant = async (
  id,
  name,
  email,
  phonenumber,
  termsAndConditions,
  costForEmployees,
  maxOrdersPerDay,
  dailyCancelationTime,
  dailyOrderTime,
  orderSendingTime,
  requestedDeliveryTime,
  workingDays,
  restaurantLocations,
) => {
  return await instance.put(`restaurant/${id}`, {
    name,
    email,
    phonenumber,
    termsAndConditions,
    costForEmployees,
    maxOrdersPerDay,
    dailyCancelationTime,
    dailyOrderTime,
    orderSendingTime,
    requestedDeliveryTime,
    workingDays,
    restaurantLocations,
    status: 'ACTIVE',
  });
};

export const getRestaurantById = async (id) => {
  return await instance.get(`restaurant/${id}`);
};

export const handleRestaurantStatus = async (id, status) => {
  return await instance.get(`restaurant/${id}/${status}`);
};

export const removeRestaurant = async (id) => {
  return await instance.delete(`restaurant/${id}`);
};

export const getAllCategories = async () => {
  return await instance.get('categories');
};

export const createCategory = async (data) => {
  return await instance.post(`categories`, data);
};

export const getAllLocations = async () => {
  return await instance.get('lookup/locations');
};

export const createMenuItem = async (restaurantId, menuId, data) => {
  return await instance.post(
    `restaurant/${restaurantId}/menu/${menuId}/menuItem`,
    data
  );
};

const getAllMenuItems = async (restaurantId, menuId) => {
  return await instance.get(
    `restaurant/${restaurantId}/menu/${menuId}/menuItems`
  );
};

const getAllMenus = async (restaurantId) => {
  return await instance.get(`restaurant/${restaurantId}/menus`);
};

export const deleteMenuItem = async (restaurantId, menuId, menuItemId) => {
  return await instance.delete(
    `restaurant/${restaurantId}/menu/${menuId}/menuItem/${menuItemId}`
  );
};

export const editMenuItem = async (restaurantId, menuId, menuItemId, data) => {
  return await instance.put(
    `restaurant/${restaurantId}/menu/${menuId}/menuItem/${menuItemId}`,
    { ...data, menuItemId: +menuItemId }
  );
};

export const getRestaurantsForDate = async (date) => {
  return await instance.get(`order/restaurants?date=${date}&status=ACTIVE`);
};

export const getLimitForOrders = async () => {
  return await instance.get('configuration/limit-for-order-days');
};

export const getTermsAndConditionsStatus = async (id) => {
  return await instance.get(`restaurant/${id}/getTermsAndConditions`);
};

export const acceptTermsAndConditions = async (id) => {
  return await instance.post(`restaurant/${id}/acceptTermsAndConditions`);
};

const RestaurantService = {
  createRestaurant,
  editRestaurant,
  removeRestaurant,
  handleRestaurantStatus,
  createMenuItem,
  editMenuItem,
  deleteMenuItem,
  getAllRestaurants,
  getRestaurantById,
  getAllCategories,
  createCategory,
  getAllLocations,
  getAllMenuItems,
  getAllMenus,
  getRestaurantsForDate,
  getLimitForOrders,
  getTermsAndConditionsStatus,
  acceptTermsAndConditions,
};

export default RestaurantService;
