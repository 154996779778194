import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal/Modal';

import { currentUser } from '../../../store/currentUser/currentUser.selector';
import { refreshTokenAsync } from '../../../store/currentUser/currentUser.thunk';
import Button from '../../atoms/Button/Button';

import './refreshTokenModal.scss';

const RefreshTokenModal = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  useEffect(() => {
    if (!user) {
      setShowModal(false);
      return;
    }

    const interval = setInterval(() => {
      setShowModal(true);
    }, 1680000);

    return () => {
      clearInterval(interval);
    };
  }, [showModal, user]);

  const refreshToken = async () => {
    setShowModal(false);
    dispatch(refreshTokenAsync());
  };

  return (
    showModal && (
      <div className="m-token-modal">
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          hasBorder={true}
          hasBannerAlt={true}
          title="Your session will expire in 2 minutes. Do you wish to stay signed in ?"
        >
          <Button
            classes="bg--blue slim square small"
            text="Yes"
            onClick={refreshToken}
          />
          <Button
            classes="bg--blue slim square small"
            text="No"
            onClick={() => setShowModal(false)}
          />
        </Modal>
      </div>
    )
  );
};

export default RefreshTokenModal;
