const Hide = () => (
  <svg
    data-testid="icon-hide"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 16.552 16.552"
  >
    <g id="icon-hide" transform="translate(0.276 0.276)">
      <path
        id="Path_805"
        data-name="Path 805"
        d="M11.96,11.96A6.713,6.713,0,0,1,8,13.333C3.333,13.333.667,8,.667,8A12.3,12.3,0,0,1,4.04,4.04M6.6,2.827A6.08,6.08,0,0,1,8,2.667C12.667,2.667,15.333,8,15.333,8a12.333,12.333,0,0,1-1.44,2.127m-4.48-.713A2,2,0,1,1,6.587,6.587"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
      <path
        id="Path_806"
        data-name="Path 806"
        d="M.667.667,15.333,15.333"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
    </g>
  </svg>
);

export default Hide;
