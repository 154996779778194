import { RESTAURANTS_ACTION_TYPES } from './restaurants.types';

export const INITIAL_STATE = {
  restaurants: [],
  isLoading: false,
  selectedRestaurant: undefined,
  categories: [],
  locations: [],
  createdMenuItemSuccessfully: false,
  menuItems: [],
  menus: [],
  selectedMenuId: undefined,
  selectedMenuItem: [],
  restaurantsForDate: [],
  limitForOrders: undefined,
  termsAndConditionsAccepted: false,
};

export const restaurantsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_START:
      return { ...state, isLoading: true, createdMenuItemSuccessfully: false };
    case RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_SUCCESS:
      return { ...state, restaurants: payload, selectedMenuId: undefined };
    case RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_END:
      return { ...state, isLoading: false };
    case RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_RESTAURANT_SUCCESS:
      return { ...state, selectedRestaurant: payload };
    case RESTAURANTS_ACTION_TYPES.REMOVE_SELECTED_RESTAURANT:
      return { ...state, selectedRestaurant: undefined };
    case RESTAURANTS_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS:
      return { ...state, categories: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_MENU_ITEMS_SUCCESS:
      return { ...state, menuItems: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_MENUS_SUCCESS:
      return { ...state, menus: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_LOCATIONS_SUCCESS:
      return { ...state, locations: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_CREATED_MENU_ITEM_SUCCESSFULLY:
      return { ...state, createdMenuItemSuccessfully: true };
    case RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_MENU_ITEM:
      return { ...state, selectedMenuItem: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_UPDATED_ALL_MENU_ITEMS:
      return { ...state, menuItems: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_MENU_ID:
      return { ...state, selectedMenuId: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_FOR_DATE:
      return { ...state, restaurantsForDate: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_LIMIT_FOR_ORDERS:
      return { ...state, limitForOrders: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_START:
      return { ...state, isLoading: true };
    case RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_SUCCESS:
      return { ...state, termsAndConditionsAccepted: payload };
    case RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_END:
      return { ...state, isLoading: false };
    case RESTAURANTS_ACTION_TYPES.ACCEPT_TERMS_AND_CONDITIONS_START:
      return { ...state, isLoading: true };
    case RESTAURANTS_ACTION_TYPES.ACCEPT_TERMS_AND_CONDITIONS_SUCCESS:
      return { ...state, termsAndConditionsAccepted: payload };
    case RESTAURANTS_ACTION_TYPES.ACCEPT_TERMS_AND_CONDITIONS_END:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
