import { CREATE_RESTAURANT } from '../constants/notification-messages';
import { handleNotificationAction } from '../store/notification/notification.action';

export function populateRestaurantDefaultValues(restaurantId, restaurant) {
  if (!restaurantId)
    // This condition is triggered only when adding a new restaurant; we want to have all the days selected;
    return {
      workingDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      },
    };

  let deliveryLocations = [];
  restaurant.locations.forEach(
    (location) => (deliveryLocations[location.locationName] = true)
  );

  return { ...restaurant, locations: deliveryLocations };
}

export function populateDeliveryLocations(restaurant) {
  return restaurant?.locations.map((location) => location.id) || [];
}

export function handleDeliveryLocations(
  location,
  deliveryLocationIndexes,
  setDeliveryLocationIndexes
) {
  const locationID = Number(location.target.id);
  const index = deliveryLocationIndexes.indexOf(locationID);

  if (location.target.checked) {
    deliveryLocationIndexes.push(locationID);
  } else {
    deliveryLocationIndexes.splice(index, 1);
  }
  setDeliveryLocationIndexes(deliveryLocationIndexes);
}

function validateDeliveryLocations(checkedLocations, dispatch) {
  if (checkedLocations.length === 0) {
    dispatch(
      handleNotificationAction({
        title: CREATE_RESTAURANT.ERROR_LOCATIONS,
        status: 'error',
      })()
    );
    return false;
  }
  return true;
}

export function validateWorkdays(workingDays, dispatch) {
  if (!Object.values(workingDays).includes(true)) {
    dispatch(
      handleNotificationAction({
        title: CREATE_RESTAURANT.ERROR_WORKDAYS,
        status: 'error',
      })()
    );
    return false;
  }
  return true;
}

export function formatCamelcase(string) {
  return string.split(/(?=[A-Z])/).join(' ');
}

export const deliveryLocationHelpers = {
  validate: validateDeliveryLocations,
};
