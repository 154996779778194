import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  currentUser,
  isAdmin,
} from '../../../store/currentUser/currentUser.selector';
import { logoutUserAsync } from '../../../store/currentUser/currentUser.thunk';
import {
  IconNav,
  IconBurger,
  IconDashboard,
  IconLogo,
  IconLogout,
  IconPlacesToEat,
  IconProfile,
  IconUsers,
  IconOrders,
} from '../../atoms/Icons';

import './navbar.scss';

const Navbar = () => {
  const admin = useSelector(isAdmin);
  const user = useSelector(currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNavBar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavBar);
  };

  const handleNavigate = (route) => {
    navigate(route);
    setShowNavbar(false);
  };

  return (
    <>
      {user ? (
        <>
          <div className="navbar navbar--mobile">
            <div
              className="navitem navitem--left--toggle"
              onClick={handleShowNavbar}
            >
              <IconNav width="32" height="32" />
            </div>
            <div
              className="navitem navitem--right"
              data-label="order now!"
              onClick={() => handleNavigate('order')}
            >
              <IconBurger
                width="38"
                height="38"
                style={{ marginTop: '-8px', marginLeft: '-2px' }}
              />
            </div>
          </div>
          <div
            className={`navbar${showNavBar ? ' navbar--visible' : ''}`}
            data-testid="user-navbar"
          >
            <div className="navbar__group">
              <div
                className="navitem navitem--left"
                data-label="dashboard"
                onClick={() => handleNavigate('dashboard')}
              >
                <IconDashboard />
              </div>
              <div
                className="navitem navitem--left"
                data-label="places to eat"
                onClick={() => handleNavigate('restaurant')}
              >
                <IconPlacesToEat />
              </div>
              {admin && (
                <div
                  className="navitem navitem--left"
                  data-label="all users"
                  onClick={() => handleNavigate('all-users')}
                >
                  <IconUsers />
                </div>
              )}
              {admin && (
                <div
                  className="navitem navitem--left"
                  data-label="order history"
                  onClick={() => handleNavigate('order-history')}
                >
                  <IconOrders />
                </div>
              )}
            </div>
            <div className="navbar__group">
              <div
                className="navitem navitem--right navitem--order"
                data-label="order now!"
                onClick={() => handleNavigate('order')}
              >
                <IconBurger
                  width="38"
                  height="38"
                  style={{ marginTop: '-8px', marginLeft: '-2px' }}
                />
              </div>
              <div
                className="navitem navitem--right"
                data-label="your profile"
                onClick={() =>
                  handleNavigate(`user-profile/${user?.userDto.id}`)
                }
              >
                <IconProfile />
              </div>
              <div
                className="navitem navitem--right"
                data-label="log out"
                onClick={() => {
                  dispatch(logoutUserAsync());
                  setShowNavbar(false);
                }}
              >
                <IconLogout />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="navbar navbar--login" data-testid="log-in-navbar">
          <div className="navitem left">
            <a
              href="https://www.valtech.com/"
              data-label="valtech.com"
              rel="noreferrer"
              target="_blank"
              className="navbar--login__logo"
            >
              <IconLogo />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
