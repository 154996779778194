const Burger = (props) => (
  <svg viewBox="0 0 58 58" {...props}>
    <path
      fill="#D5DBE1"
      d="M20.3,18c-5.3,2-9.2,6.9-9.8,12.8h3C13.9,25.6,16.4,21.1,20.3,18z"
    />
    <path
      fill="#D5DBE1"
      d="M13.4,49.7v-0.6h-3v0.6c0,2.8,2.2,5,4.9,5.2C14.1,53.5,13.4,51.7,13.4,49.7z"
    />
    <path
      fill="#33CAFB"
      d="M49.3,38.3c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H8.7C7.8,41.6,7,40.9,7,40s0.7-1.6,1.6-1.6H49.3"
    />
    <rect x="30.5" y="4" fill="#33CAFB" width="8.5" height="5.2" />
    <path
      fill="#00BDFA"
      d="M10,40c0-0.6,0.1-1.1,0.3-1.6H8.7C7.8,38.3,7,39,7,40s0.7,1.6,1.6,1.6h1.7C10.2,41.1,10,40.5,10,40z"
    />
    <path
      d="M49.1,46.1H8.9c-0.8,0-1.5,0.7-1.5,1.5v2.1c0,4.6,3.7,8.3,8.3,8.3h26.7c4.6,0,8.3-3.7,8.3-8.3v-2.1
		C50.6,46.8,49.9,46.1,49.1,46.1z M47.6,49.7c0,2.9-2.4,5.3-5.3,5.3H15.6c-2.9,0-5.3-2.4-5.3-5.3v-0.6h37.2V49.7z"
    />
    <path
      d="M8.9,33.8h40.2c0.8,0,1.5-0.7,1.5-1.5c0-10-8.2-18.2-18.2-18.2h-1.9v-2h10c0.8,0,1.5-0.7,1.5-1.5V2.5C42,1.6,41.3,1,40.5,1
		H30.4C30.1,0.4,29.6,0,29,0c-0.8,0-1.5,0.7-1.5,1.5v0.9v8.2v3.5h-1.9c-10,0-18.2,8.2-18.2,18.2C7.4,33.1,8,33.8,8.9,33.8z M30.5,4
		H39v5.2h-8.5V4z M25.6,17.1h6.8c7.9,0,14.4,6,15.2,13.7H10.4C11.2,23.1,17.7,17.1,25.6,17.1z"
    />
    <path
      d="M49.3,38.3c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H8.7C7.8,41.6,7,40.9,7,40s0.7-1.6,1.6-1.6H49.3 M49.3,35.3H8.7
		C6.1,35.3,4,37.4,4,40s2.1,4.6,4.6,4.6h40.6c2.6,0,4.6-2.1,4.6-4.6S51.9,35.3,49.3,35.3L49.3,35.3z"
    />
    <path
      d="M20.8,21.6l-1.9-1c-0.5-0.3-1.1-0.1-1.3,0.4c-0.3,0.5-0.1,1.1,0.4,1.3l1.9,1c0.1,0.1,0.3,0.1,0.5,0.1
    c0.4,0,0.7-0.2,0.9-0.5C21.5,22.4,21.3,21.8,20.8,21.6z"
    />
    <path
      d="M17.2,24.9l-2.8,1c-0.5,0.2-0.8,0.8-0.6,1.3c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3-0.1l2.8-1c0.5-0.2,0.8-0.8,0.6-1.3
    S17.8,24.7,17.2,24.9z"
    />
    <path
      d="M24.7,23l1.4-2.2c0.3-0.5,0.1-1.1-0.3-1.4c-0.5-0.3-1.1-0.1-1.4,0.3L23,21.9c-0.3,0.5-0.1,1.1,0.3,1.4
    c0.2,0.1,0.3,0.2,0.5,0.2C24.2,23.5,24.5,23.3,24.7,23z"
    />
    <path
      d="M27.9,25.4l-3-1.1c-0.5-0.2-1.1,0.1-1.3,0.6s0.1,1.1,0.6,1.3l3,1.1c0.1,0,0.2,0.1,0.3,0.1c0.4,0,0.8-0.2,0.9-0.7
    C28.6,26.2,28.4,25.6,27.9,25.4z"
    />
    <path
      d="M34.5,20.3c-0.2-0.5-0.8-0.7-1.3-0.5l-3.5,1.7c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1
    l3.5-1.7C34.5,21.4,34.7,20.8,34.5,20.3z"
    />
    <path
      d="M35.4,24.9L33,24c-0.5-0.2-1.1,0.1-1.3,0.6c-0.2,0.5,0.1,1.1,0.6,1.3l2.4,0.9c0.1,0,0.2,0.1,0.4,0.1
    c0.4,0,0.8-0.2,0.9-0.6C36.2,25.7,35.9,25.1,35.4,24.9z"
    />
    <path
      d="M40.6,24.5L39.4,22c-0.2-0.5-0.8-0.7-1.3-0.5c-0.5,0.2-0.7,0.8-0.5,1.3l1.2,2.5c0.2,0.4,0.5,0.6,0.9,0.6
    c0.1,0,0.3,0,0.4-0.1C40.6,25.6,40.8,25,40.6,24.5z"
    />
    <path
      d="M44.2,25.1c-0.4-0.4-1-0.4-1.4,0l-1.9,1.9c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.9-1.9
    C44.6,26.2,44.6,25.5,44.2,25.1z"
    />
  </svg>
);

export default Burger;
