export const NEW_ACCOUNT_REQUEST = {
  SUCCESS: 'An account has been requested, please confirm your email address',
  ERROR: 'Account request could not be created. Please try again later.',
};

export const NEW_ACCOUNT_RESPONSE = {
  SUCCESS: 'New user was successfully added!',
  ERROR: 'This request was denied!',
};

export const SET_PASSWORD = {
  SUCCESS_TITLE: 'Your password has been successfully set!',
  SUCCESS_MESSAGE:
    'You can proceed now and log in with the password you have chosen.',
  ERROR:
    'Your password has not been set. If you change your mind, you can always set a new password later.',
};

export const FORGOT_PASSWORD = {
  SUCCESS_TITLE: 'Password reset has been initiated for your account',
  SUCCESS_MESSAGE:
    'Check your email for instructions on how to reset your password',
  ERROR: 'An error occurred. Please try again later.',
};

export const CREATE_USER = {
  SUCCESS: 'User has been created',
  ERROR: 'User could not be created. Please try again later',
};

export const MODIFY_USER = {
  SUCCESS: 'User has been modified',
  ERROR:
    'User update could not take place at the current time. Please try again later',
};

export const MODIFY_IMAGE = {
  SUCCESS: 'User image has been modified',
  ERROR:
    'User image update could not take place at the current time. Please try again later',
};

export const INACTIVATE_USER = {
  SUCCESS: 'Selected user was deactivated !',
  ERROR:
    'User deactivation could not take place at the current time. Please try again later',
};

export const ACTIVATE_USER = {
  SUCCESS: 'Selected user was activated !',
  ERROR:
    'User activation could not take place at the current time. Please try again later',
};

export const DELETE_USER = {
  SUCCESS: 'Selected user was removed',
  ERROR:
    'User removal could not take place at the current time. Please try again later',
};

export const CREATE_RESTAURANT = {
  SUCCESS: 'Restaurant has been created!',
  ERROR:
    'Restaurant creation could not take place at the current time. Please try again later',
  ERROR_WORKDAYS: 'Please select at least one working day',
  ERROR_LOCATIONS: 'Please select at least one delivery location',
};
export const EDIT_RESTAURANT = {
  SUCCESS: 'Restaurant has been updated!',
  ERROR:
    'Restaurant update could not take place at the current time. Please try again later',
};

export const DEACTIVATE_RESTAURANT = {
  SUCCESS: 'Selected restaurant was closed for orders !',
  ERROR:
    'Restaurant deactivation could not take place at the current time. Please try again later',
};

export const ACTIVATE_RESTAURANT = {
  SUCCESS: 'Selected restaurant is open for orders !',
  ERROR:
    'Restaurant activation could not take place at the current time. Please try again later',
};

export const REMOVE_RESTAURANT = {
  SUCCESS: 'Selected restaurant was removed!',
  ERROR: 'Restaurant could not be removed!',
};

export const CREATE_MENU_ITEM = {
  SUCCESS: 'Menu item has been created',
  ERROR: 'Menu item could not be created. Please try again later',
};

export const CANCEL_ORDER = {
  SUCCESS: 'Order has been canceled',
  ERROR: 'Order could not be canceled. Please try again later.',
};

export const CREATE_ORDER = {
  SUCCESS: 'Order has been created',
  ERROR: 'Order could not be created. Please try again later',
};

export const DELETE_MENU_ITEM = {
  SUCCESS: 'Menu item has been deleted',
  ERROR: 'Menu item could not be deleted. Please try again later',
};

export const UPDATE_PROFILE = {
  SUCCESS: 'Your profile has been updated',
  ERROR:
    'The update could not take place at the current time. Please try again later',
};

export const UPDATE_IMAGE = {
  SUCCESS: 'Your profile image has been updated',
  ERROR:
    'The image update could not take place at the current time. Please try again later',
};

export const ADD_IMAGE = {
  SUCCESS: 'Profile image has been added',
  ERROR:
    'The image update could not take place at the current time. Please try again later!',
};

export const DELETE_IMAGE = {
  SUCCESS: 'Profile image has been deleted',
  ERROR:
    'The image could not be deleted at the current time. Please try again later!',
};

export const CREATE_CATEGORY = {
  SUCCESS: 'Category has been created',
  ERROR: 'Category could not be created. Please try again later',
};

export const INVALID_INPUT = {
  GENERAL_LENGTH: 'Max length is 100 characters',
  COMMENT_LENGTH: 'Max length is 200 characters',
  MAX_LENGTH: 'Max length is 500 characters',
  TERMS_AND_CONDITIONS: 'Max length is 2000 characters',
  NAME: 'No special characters allowed',
  EMAIL: 'Please use a valid email address',
  VALTECH_MAIL: 'Email domain not allowed',
  PHONENUMBER: 'Must start with +3897 or +3892 and contain 11 digits',
  TIME: 'Must not be a negative number',
  NUMBER: 'Card ID should be only numbers & max 10 digits',
};

export const DECLINED_REQUEST = {
  LOGIN_TITLE: 'Login Error',
  LOGIN_MESSAGE: 'An unexpected error occurred. Please try logging in again.',
  LOGOUT_TITLE: 'Logout Error',
  LOGOUT_MESSAGE: 'An unexpected error occurred. Please try logging out again.',
  ALL_USERS: 'Could not load users. Please try again later!',
  ALL_ORDERS: 'Could not load orders. Please try again later!',
  YOUR_PROFILE: 'Could not display your profile. Something went wrong!',
  EDIT_PROFILE: 'An error occurred. Please try again later.',
  ACCOUNT_REQUESTS: 'Could not load account requests. Something went wrong!',
  ERROR_MESSAGE: 'Something went wrong! Try again later.',
  ALL_RESTAURANTS: 'Could not load restaurants. Something went wrong!',
  RESTAURANT: 'Could not display the restaurant. Something went wrong!',
  MENU_ITEMS: 'Could not load menu items. Something went wrong!',
  MENUS: 'Could not load menus. Something went wrong!',
  LOCATIONS: 'Could not load restaurant locations. Something went wrong!',
  SET_PASSWORD: 'Could not set your password. Something went wrong!',
  CONFIRM_EMAIL: 'Could not confirm your email. Something went wrong!',
  RESTAURANTS_FOR_DATE:
    'Could not get available restaurant for the selected day. Something went wrong!',
  ORDER_DATE: 'Could not set filter date',
  LIMIT_FOR_ORDERS: 'Could not get limit for orders. Something went wrong!',
  TERMS_AND_CONDITIONS_STATUS:
    'Could not get status of terms and conditions for restaurant. Something went wrong!',
  ACCEPT_TERMS_AND_CONDITIONS:
    'Could not accept the terms and conditions of the restaurant. Something went wrong!',
};

export const INVALID_PASSWORD = {
  SHORT: 'Must contain at least 8 characters',
  LONG: 'Must be less than 30 characters',
  MISMATCH:
    'Must contain at least one uppercase and one lowercase, one number and one special character',
};

export const SESSION = {
  EXPIRED: 'Session Expired. You have been logged out.',
  CANNOT_CONTINUE: 'An error occurred. Unable to refresh the token!',
};

export const EDIT_MENU_ITEM = {
  SUCCESS: 'Menu item has been edited',
  ERROR: 'Menu item could not be edited. Please try again later',
};

export const SELECTED_DAY = {
  PAST: 'You cannot select a date in the past',
  TEN_DAYS: 'You cannot select a date more than 10 days from the current date',
};

export const MEALS_MISSING = {
  MAIN: 'Pleace select a main meal first!',
  SIDE: 'Please select a side meal first!',
};

export const TERMS = "Do you agree with the restaurant's terms?";
