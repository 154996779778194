import './checkboxGroup.scss';

const CheckboxGroup = ({ title, children }) => {
  return (
    <div className="checkbox-group">
      <div className="checkbox-group__title">{title}</div>
      <div className="checkbox-group__items">{children}</div>
    </div>
  );
};

export default CheckboxGroup;
