import { handleNotificationAction } from '../notification/notification.action';

import {
  ACTIVATE_USER,
  CREATE_USER,
  DECLINED_REQUEST,
  DELETE_USER,
  FORGOT_PASSWORD,
  INACTIVATE_USER,
  NEW_ACCOUNT_RESPONSE,
  SET_PASSWORD,
} from '../../constants/notification-messages';
import UserService from '../../services/user.service';
import userService from '../../services/user.service';

import {
  allUsersStart,
  allUsersSuccess,
  actionEnd,
  actionStart,
  accountRequestsSuccess,
  selectedUserStart,
  selectedUserSuccess,
  updatedAllUsers,
  successfullyCreated,
} from './users.action';

export const allUsersAsync = () => async (dispatch) => {
  dispatch(allUsersStart()());
  try {
    const response = await UserService.getAllUsers();
    dispatch(allUsersSuccess(response.data)());
  } catch {
    dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.ALL_USERS,
        status: 'error',
      })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const allUsersPaginatedAsync =
  (usersPerPage, search, company) => async (dispatch) => {
    dispatch(allUsersStart()());
    try {
      const response = await UserService.getAllUsersPaginated(
        usersPerPage,
        12,
        search,
        company
      );
      dispatch(allUsersSuccess(response.data)());
    } catch {
      dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.ALL_USERS,
        status: 'error',
      })()
    );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const createNewUserAsync =
  (firstName, lastName, email, cardId, company) => async (dispatch) => {
    dispatch(actionStart()());
    try {
      const newUser = await UserService.createNewUser(
        firstName,
        lastName,
        email,
        cardId,
        company
      );

      if (newUser.status === 200 || newUser.status === 201) {
        dispatch(handleNotificationAction({ title: CREATE_USER.SUCCESS })());
        dispatch(successfullyCreated()());
      } else {
        dispatch(
          handleNotificationAction({
            title: newUser.data.message || CREATE_USER.ERROR,
            status: 'error',
          })()
        );
      }
    } catch {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.ERROR_MESSAGE,
          status: 'error',
        })()
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const accountRequestsAsync = () => async (dispatch) => {
  dispatch(actionStart()());
  try {
    const response = await userService.getAllUsersRequests();
    dispatch(accountRequestsSuccess(response.data)());
  } catch {
    dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.ACCOUNT_REQUESTS,
        status: 'error',
      })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const userStatusAsync = (id, allUsers) => async (dispatch) => {
  dispatch(actionStart()());
  const selectedUser = allUsers.find((user) => user.id === id);
  const userStatus = selectedUser.userStatus;
  const userStatusUpdated = userStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
  try {
    await userService.handleUserStatus(id, userStatusUpdated);
    dispatch(
      userStatus === 'ACTIVE'
        ? handleNotificationAction({
            title: INACTIVATE_USER.SUCCESS,
            status: 'error',
          })()
        : handleNotificationAction({ title: ACTIVATE_USER.SUCCESS })()
    );
    const updatedUsers = allUsers.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          userStatus: userStatusUpdated,
        };
      }
      return user;
    });
    dispatch(updatedAllUsers(updatedUsers)());
  } catch {
    dispatch(
      handleNotificationAction({
        title:
          userStatus === 'ACTIVE' ? INACTIVATE_USER.ERROR : ACTIVATE_USER.ERROR,
        status: 'error',
      })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const handleRequestAsync =
  (id, approvalStatus, userRequests) => async (dispatch) => {
    const filteredRequests = userRequests.filter((user) => user.id !== id);
    dispatch(actionStart()());
    try {
      await userService.handleUserRequest(id, approvalStatus);
      dispatch(
        approvalStatus === 'APPROVED'
          ? handleNotificationAction({ title: NEW_ACCOUNT_RESPONSE.SUCCESS })()
          : handleNotificationAction({
              title: NEW_ACCOUNT_RESPONSE.ERROR,
              status: 'error',
            })()
      );
      dispatch(accountRequestsSuccess(filteredRequests)());
    } catch {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.ERROR_MESSAGE,
          status: 'error',
        })()
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const getUserAsync = (userId) => async (dispatch) => {
  dispatch(selectedUserStart()());
  try {
    const response = await userService.getUserById(userId);
    dispatch(selectedUserSuccess(response.data)());
  } catch {
    dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.YOUR_PROFILE,
        status: 'error',
      })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const deleteUserAsync = (userId, navigate) => async (dispatch) => {
  dispatch(actionStart()());
  try {
    await userService.deleteUser(userId);
    dispatch(
      handleNotificationAction({
        title: DELETE_USER.SUCCESS,
        status: 'error',
      })()
    );
    navigate('/all-users');
  } catch {
    dispatch(
      handleNotificationAction({ title: DELETE_USER.ERROR, status: 'error' })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const setPasswordAsync =
  (navigate, token, password) => async (dispatch) => {
    dispatch(actionStart()());
    try {
      await userService.setPassword(token, password);
      dispatch(
        handleNotificationAction({
          title: SET_PASSWORD.SUCCESS_TITLE,
          message: SET_PASSWORD.SUCCESS_MESSAGE,
        })()
      );
      navigate('/');
    } catch {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.SET_PASSWORD,
          status: 'error',
        })()
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const resetPasswordAsync = (navigate, email) => async (dispatch) => {
  dispatch(actionStart()());
  try {
    await userService.resetPassword(email);
    dispatch(
      handleNotificationAction({
        title: FORGOT_PASSWORD.SUCCESS_TITLE,
        message: FORGOT_PASSWORD.SUCCESS_MESSAGE,
      })()
    );
    navigate('/');
  } catch (error) {
    const errorMessage = error.response.data.message;
    dispatch(
      handleNotificationAction({
        title: errorMessage || FORGOT_PASSWORD.ERROR,
        status: 'error',
      })()
    );
  } finally {
    dispatch(actionEnd()());
  }
};
