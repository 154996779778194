import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { accountRequests } from '../../../store/users/users.selector';
import { handleRequestAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';

const AccountRequestRow = ({ user }) => {
  const { id, firstName, lastName, email, createdDate, company } = user;
  const date = new Date(createdDate).toLocaleDateString();
  const userRequests = useSelector(accountRequests);
  const dispatch = useDispatch();

  const handleRequest = (approvalStatus) =>
    dispatch(handleRequestAsync(id, approvalStatus, userRequests));

  return (
    <div className="table__row">
      <div className="table__cell date" data-label="Date">
        <p className="cell__data">{date}</p>
      </div>
      <div
        className="table__cell id"
        data-testid="account-request--id"
        data-label="ID"
      >
        <p className="cell__data">{id}</p>
      </div>
      <div
        className="table__cell name"
        data-testid="account-request--name"
        data-label="Name"
      >
        <h6 className="cell__data">
          {firstName} {lastName}
        </h6>
      </div>
      <div
        className="table__cell email"
        data-testid="account-request-email"
        data-label="E-mail"
      >
        <h6 className="cell__data">{email}</h6>
      </div>
      <div
        className="table__cell company"
        data-testid="account-request-company"
        data-label="Entity"
      >
        <h6 className="cell__data">{company}</h6>
      </div>
      <div data-testid="buttons" className="table__cell buttons">
        <Button
          testID="button__approved"
          onClick={() => handleRequest('APPROVED')}
          text="approve"
          classes="bg--black hover-bg--green large no-border"
        />
        <Button
          testID="button__cancel"
          onClick={() => handleRequest('DECLINED')}
          icon="close"
          hoverIcon="close"
          classes="bg--black hover-bg--red small no-border"
        />
      </div>
    </div>
  );
};

export default AccountRequestRow;
