import { useSelector } from 'react-redux';

import {
  selectedMenuItem,
  selectedSideMenuItem,
  selectedExtraMenuItems,
  selectedOrderDate,
} from '../../../store/orders/orders.selector';
import { selectedRestaurant } from '../../../store/restaurants/restaurants.selector';

const FinalStep = () => {
  const mainItem = useSelector(selectedMenuItem);
  const sideItem = useSelector(selectedSideMenuItem);
  const extraItems = useSelector(selectedExtraMenuItems);
  const restaurant = useSelector(selectedRestaurant);
  const date = useSelector(selectedOrderDate);

  return (
    <>
      <h4 className="p-order__heading">Order Review</h4>
      <ul className="p-order__review">
        <h5>{restaurant.name}</h5>
        <p className="date">{date}</p>
        <li>
          <strong>Main Item</strong>
          <p>{mainItem.name}</p>
        </li>
        {sideItem && (
          <li>
            <strong>Side Item</strong>
            <p>{sideItem.name}</p>
          </li>
        )}
        {extraItems.length > 0 && (
          <li>
            <strong>Extra Items</strong>
            {extraItems.map((item) => (
              <p key={item.menuItemId}>{item.name}</p>
            ))}
          </li>
        )}
      </ul>
    </>
  );
};

export default FinalStep;
