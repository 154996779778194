import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { currentUser } from '../../../store/currentUser/currentUser.selector';
import { currentUserOrders } from '../../../store/orders/orders.selector';
import { fetchCurrentUserOrders } from '../../../store/orders/orders.thunk';
import Button from '../../atoms/Button/Button';
import Modal from '../../molecules/Modal/Modal';
import Table from '../../molecules/Table/Table';

import './dashboard.scss';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const userOrders = useSelector(currentUserOrders).content;
  const lastName = useSelector(currentUser).userDto.lastName;
  const userId = useSelector(currentUser).userDto.id;
  const [orderModal, setShowOrderModal] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrentUserOrders(userId));
  }, [userId]); // eslint-disable-line

  useEffect(() => {
    userOrders &&
      setTableData(
        userOrders
          .filter((order) => order.orderStatus === 'PENDING')
          .map((order) => [
            order.date,
            order.userOrderId,
            order.name,
            order.restaurantName,
            `${order.mainItemAndSideItem} ${order.extraItem.join(', ')}`,
            order.comments,
            order.orderStatus,
          ])
      );
  }, [userOrders]); // eslint-disable-line

  return (
    <div className="p-dashboard">
      <h1 className="p-dashboard__heading">
        Hi {lastName}!<span>Feeling Hungry?</span>
      </h1>
      <div className="d-flex gap-1">
        <Button
          classes="bg--blue hover-bg--black"
          text="order now!"
          onClick={() => navigate('/order')}
        />
        {tableData.length >= 1 && (
          <Button
            classes="bg--blue hover-bg--black"
            text="My orders!"
            onClick={() => setShowOrderModal(true)}
          />
        )}
      </div>
      {orderModal && (
        <Modal
          isOrderModal={true}
          showModal={orderModal}
          setShowModal={setShowOrderModal}
          hasBorder={true}
          hasBanner={true}
        >
          <div className="p-dashboard__table-container">
            <Table
              tableHeaders={[
                'Date',
                'Order ID',
                'User',
                'Restaurant',
                'Meals Ordered',
                'Comments',
                'Status',
                'Action',
              ]}
              tableData={tableData}
              isOrderTable={true}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;
