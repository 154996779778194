export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const today = new Date();

export const newSelectedDate = (currentDate, offSet, day) =>
  new Date(currentDate.getFullYear(), currentDate.getMonth() + offSet, day);

export const diffInDays = (date) =>
  Math.floor((date - today) / (1000 * 60 * 60 * 24)) - 1;

const nextTenDays = (limitDays) =>
  new Date(today.getFullYear(), today.getMonth(), today.getDate() + limitDays);

export const isSameMonth = (limitDays) =>
  today.getMonth() === nextTenDays(limitDays).getMonth();

export const firstDayOfMonth = (currentDate) =>
  new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

const daysInMonth = (currentDate) =>
  new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

export const days = (currentDate) =>
  Array.from({ length: daysInMonth(currentDate) }, (_, i) => i + 1);

export const isDisabled = (newDate, limitDays) => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    newDate < tomorrow.setHours(0, 0, 0, 0) || diffInDays(newDate) >= limitDays
  );
};

export const getDayClassNames = (
  currentDate,
  selectedDay,
  day,
  isDisabled,
  limitDays
) => {
  const newDate = newSelectedDate(currentDate, 0, day);
  const isDayDisabled = isDisabled(newDate, limitDays);
  const classNames = ['calendar__day'];
  selectedDay === day && classNames.push('selected');
  isDayDisabled && classNames.push('disabled');

  return classNames.join(' ');
};
