import { IconPlus } from '../Icons';

import './image.scss';

const Image = ({ className, src, alt, onClick }) => {
  return src ? (
    <img
      className={`image image--${className}`}
      src={src}
      alt={alt}
      data-testid="image-element"
    />
  ) : (
    <div
      className={`image image--empty image--${className}`}
      onClick={onClick}
      data-testid="add-image-element"
    >
      <IconPlus />
    </div>
  );
};

export default Image;
