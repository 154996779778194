import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currentUser } from '../../../store/currentUser/currentUser.selector';
import { accountRequests } from '../../../store/users/users.selector';
import { accountRequestsAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import AccountRequestsTable from '../../molecules/AccountRequestTable/AccountRequestTable';

import './AccountRequests.scss';

const AccountRequests = () => {
  const usersRequests = useSelector(accountRequests);
  const userId = useSelector(currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountRequestsAsync());
  }, []); //eslint-disable-line

  return (
    <div className="row">
      <div className="col__desktop-10 col__laptop-10 p-account-request">
        <h1 className="p-account-request__main-heading" data-testid="header">
          Account Requests
        </h1>
        <AccountRequestsTable userList={usersRequests} />
        <div className="p-account-request__link">
          <Link to={`/user-profile/${userId?.userDto.id}/`}>
            <Button
              icon="arrow-left"
              hoverText="go back"
              classes="hover-bg--black"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default AccountRequests;
