import { useFormContext } from 'react-hook-form';

import { IconAlert } from '../Icons';

import './inputNumber.scss';

const InputNumber = ({
  title = 'title',
  name = 'name',
  label = 'minutes',
  validation,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="input-number-container">
      <h5 className="input-number-container__title">{title}</h5>
      <div className="input-number-container__inputs">
        <span className="input-number" data-label={label}>
          <input
            type="number"
            name={name}
            {...register(name, {
              pattern: {
                value: validation?.pattern,
                message: validation?.patternMessage,
              },
            })}
            className="input-number__input"
          />
        </span>
      </div>
      {errors[name] && (
        <p
          id="error-message"
          className="a-input-field__error-message"
          data-testid="error-message"
        >
          <IconAlert />
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default InputNumber;
