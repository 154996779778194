import { filteredSideItems } from '../MenuItemForm/filterFunctions';

import OrderItem from '../../molecules/OrderItem/OrderItem';

const Step4 = ({ allItems, orderMainMenuItem }) => {
  const allSideMeals = filteredSideItems(allItems, [orderMainMenuItem]);

  return (
    <>
      <h4 className="p-order__heading">Please choose a side dish!</h4>
      <div className="row row__large p-order__menu-wrapper">
        {allSideMeals.length > 0 ? (
          <div className="p-order__foods">
            <OrderItem items={allSideMeals} type="side" />
          </div>
        ) : (
          <h4>No side dish for the selected menu item found</h4>
        )}
      </div>
    </>
  );
};

export default Step4;
