import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DECLINED_REQUEST,
  INVALID_INPUT,
} from '../../../constants/notification-messages';
import { REGEX_INPUT } from '../../../constants/regex-patterns';
import {
  currentUser,
  isAdmin,
} from '../../../store/currentUser/currentUser.selector';
import {
  editProfileAsync,
  updateProfileAsync,
  addProfileImageAsync,
  editProfileImageAsync,
  deleteProfileImageAsync,
} from '../../../store/currentUser/currentUser.thunk';
import { handleNotificationAction } from '../../../store/notification/notification.action';
import { selectedUser } from '../../../store/users/users.selector';
import { getUserAsync } from '../../../store/users/users.thunk';
import { companies, userRoleTypes } from '../../../utils/options';
import Button from '../../atoms/Button/Button';
import { IconEdit } from '../../atoms/Icons';
import Input from '../../atoms/Input/Input';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Form from '../../molecules/Form/Form';

import './editProfile.scss';

const inputEditIcon = {
  firstName: true,
  lastName: true,
  email: true,
  cardId: true,
};

const EditProfile = () => {
  const [iconIsShown, setIconIsShown] = useState(inputEditIcon);
  const [profileImage, setProfileImage] = useState('');
  const [imageChanged, setImageChanged] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currUser = useSelector(currentUser).userDto;
  const visitedUser = useSelector(selectedUser);
  const admin = useSelector(isAdmin);

  const personal = currUser.id === Number(userId);

  useEffect(() => {
    if (!personal && !visitedUser) {
      dispatch(getUserAsync(userId));
    }
  }, []); // eslint-disable-line

  const toggleIcon = (input, value) => {
    setIconIsShown((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  useEffect(() => {
    if (!visitedUser && !currUser.id) {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.EDIT_PROFILE,
          status: 'error',
        })()
      );
      navigate(-1);
    }
  }, [visitedUser]); //eslint-disable-line

  useEffect(() => {
    if (visitedUser?.imageDto) {
      fetch(`/api/image/data/${visitedUser.imageDto.id}`)
        .then((response) => response.blob())
        .then((blob) => {
          setProfileImage(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [visitedUser?.imageDto]);

  const handleSubmit = (data) => {
    const { firstName, lastName, email, cardId, image, userRole, company } =
      data;

    !personal
      ? dispatch(
          editProfileAsync(
            userId,
            firstName,
            lastName,
            email,
            cardId,
            userRole,
            company
          )
        )
      : dispatch(updateProfileAsync(firstName, lastName, email, cardId));

    if (imageChanged) {
      if (image && visitedUser.imageDto) {
        dispatch(
          editProfileImageAsync(
            userId,
            currUser.id,
            visitedUser.imageDto.id,
            image
          )
        );
      }
      if (image && !visitedUser.imageDto) {
        dispatch(addProfileImageAsync(userId, currUser.id, image));
      }
      if (!image && visitedUser.imageDto) {
        dispatch(deleteProfileImageAsync(visitedUser.imageDto.id));
      }

      setImageChanged(false);
    }
  };

  return (
    <div className="edit-profile">
      <h1 className="edit-profile__heading">Edit Profile</h1>
      <Form
        className="edit-profile__form"
        onSubmit={handleSubmit}
        defaultValues={{
          firstName:
            personal && currUser ? currUser.firstName : visitedUser.firstName,
          lastName:
            personal && currUser ? currUser.lastName : visitedUser.lastName,
          email: personal && currUser ? currUser.email : visitedUser.email,
          cardId: personal && currUser ? currUser.cardId : visitedUser.cardId,
          userRole:
            personal && currUser
              ? currUser.role
              : visitedUser
              ? visitedUser.userRole
              : '',
          company:
            personal && currUser
              ? currUser.company
              : visitedUser
              ? visitedUser.company
              : '',
        }}
      >
        <Input
          className="filled"
          label="first name"
          name="firstName"
          validation={{
            pattern: REGEX_INPUT.NAME,
            patternMessage: INVALID_INPUT.NAME,
            maxLength: 100,
            maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
          }}
          title={INVALID_INPUT.NAME || INVALID_INPUT.GENERAL_LENGTH}
          required
          type="text"
          onBlur={() => toggleIcon('firstName', true)}
          onFocus={() => toggleIcon('firstName', false)}
          icon={iconIsShown.firstName && <IconEdit />}
        />
        <Input
          className="filled"
          label="last name"
          name="lastName"
          validation={{
            pattern: REGEX_INPUT.NAME,
            patternMessage: INVALID_INPUT.NAME,
            maxLength: 100,
            maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
          }}
          title={INVALID_INPUT.NAME || INVALID_INPUT.GENERAL_LENGTH}
          required
          type="text"
          onBlur={() => toggleIcon('lastName', true)}
          onFocus={() => toggleIcon('lastName', false)}
          icon={iconIsShown.lastName && <IconEdit />}
        />
        <Input
          className="filled"
          label="e-mail"
          name="email"
          validation={{
            pattern: REGEX_INPUT.VALTECH_MAIL,
            patternMessage: INVALID_INPUT.VALTECH_MAIL,
          }}
          title={INVALID_INPUT.VALTECH_MAIL}
          required
          type="text"
          readOnly={!admin}
          onBlur={() => toggleIcon('email', true)}
          onFocus={() => toggleIcon('email', false)}
          icon={admin && iconIsShown.email && <IconEdit />}
        />
        <Input
          className="filled"
          label="card id"
          name="cardId"
          validation={{
            pattern: REGEX_INPUT.NUMBER,
            patternMessage: INVALID_INPUT.NUMBER,
            maxLength: 10,
            maxLengthMessage: INVALID_INPUT.NUMBER,
          }}
          title={INVALID_INPUT.NUMBER}
          required
          type="text"
          onBlur={() => toggleIcon('cardId', true)}
          onFocus={() => toggleIcon('cardId', false)}
          icon={iconIsShown.lastName && <IconEdit />}
        />
        {admin && (
          <SelectInput label="Role" name="userRole" options={userRoleTypes} />
        )}
        {admin && (
          <SelectInput label="Entity" name="company" options={companies} />
        )}
        {/*Temporarily uncommented uploading of profile image as its causing issues iwth FE and it is not used anywhere in the app except for the admin*/}
        {/*<div className="edit-profile__image">*/}
        {/*  <InputImage*/}
        {/*    label={'Profile Image'}*/}
        {/*    id="image"*/}
        {/*    direction="horizontal"*/}
        {/*    name="image"*/}
        {/*    src={profileImage || null}*/}
        {/*    validation={{*/}
        {/*      required: false,*/}
        {/*    }}*/}
        {/*    onImageChange={() => setImageChanged(true)}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="edit-profile__action-buttons">
          <Button
            type="submit"
            text="save"
            hoverText="confirm"
            classes="bg--black hover-bg--green large"
          />
          <Button
            onClick={() => navigate(-1)}
            type="button"
            icon="close"
            hoverIcon="close"
            classes="bg--black hover-bg--red small"
          />
        </div>
      </Form>
    </div>
  );
};
export default EditProfile;
