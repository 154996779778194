const Users = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="30"
    viewBox="0 0 42.879 30"
  >
    <g id="icon-users" transform="translate(-85.333 -85.333)">
      <g
        id="Group_305"
        data-name="Group 305"
        transform="translate(85.333 85.333)"
      >
        <circle
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="6.071"
          cy="6.071"
          r="6.071"
          transform="translate(6.786 1.071)"
          fill="#fff"
        />
        <path
          id="Path_1134"
          data-name="Path 1134"
          d="M156.1,102.071a6.054,6.054,0,0,1,4.464-5.829A5.983,5.983,0,0,0,158.96,96a6.071,6.071,0,0,0,0,12.143,5.983,5.983,0,0,0,1.607-.243A6.054,6.054,0,0,1,156.1,102.071Z"
          transform="translate(-146.103 -94.929)"
          fill="#d5dbe1"
        />
        <path
          id="Path_1135"
          data-name="Path 1135"
          d="M115.486,266.667H101.714A5.715,5.715,0,0,0,96,272.381v5h25.2v-5A5.715,5.715,0,0,0,115.486,266.667Z"
          transform="translate(-94.929 -248.453)"
          fill="#fff"
        />
        <path
          id="Path_1136"
          data-name="Path 1136"
          d="M104.929,266.667h-3.214A5.715,5.715,0,0,0,96,272.381v5h3.214v-5A5.715,5.715,0,0,1,104.929,266.667Z"
          transform="translate(-94.929 -248.453)"
          fill="#d5dbe1"
        />
        <path
          id="Path_1145"
          data-name="Path 1145"
          d="M149.365,99.619a7.143,7.143,0,1,1,7.143-7.143A7.151,7.151,0,0,1,149.365,99.619Zm0-12.143a5,5,0,1,0,5,5A5.005,5.005,0,0,0,149.365,87.476Z"
          transform="translate(-136.508 -85.333)"
        />
        <path
          id="Path_1146"
          data-name="Path 1146"
          d="M99.619,268.857H86.4a1.072,1.072,0,0,1-1.071-1.071v-5A6.794,6.794,0,0,1,92.119,256h12.143v2.143H92.119a4.648,4.648,0,0,0-4.643,4.643v3.929H99.619Z"
          transform="translate(-85.333 -238.857)"
        />
      </g>
      <g
        id="Group_306"
        data-name="Group 306"
        transform="translate(93.083 85.333)"
      >
        <circle
          id="Ellipse_2-2"
          data-name="Ellipse 2"
          cx="6.071"
          cy="6.071"
          r="6.071"
          transform="translate(6.786 1.071)"
          fill="#fff"
        />
        <path
          id="Path_1134-2"
          data-name="Path 1134"
          d="M156.1,102.071a6.054,6.054,0,0,1,4.464-5.829A5.983,5.983,0,0,0,158.96,96a6.071,6.071,0,0,0,0,12.143,5.983,5.983,0,0,0,1.607-.243A6.054,6.054,0,0,1,156.1,102.071Z"
          transform="translate(-146.103 -94.929)"
          fill="#d5dbe1"
        />
        <path
          id="Path_1135-2"
          data-name="Path 1135"
          d="M110.568,266.667h-10.3c-2.359,0-4.272,2.559-4.272,5.714v5h18.84v-5C114.84,269.226,112.928,266.667,110.568,266.667Z"
          transform="translate(-95.212 -248.453)"
          fill="#fff"
        />
        <path
          id="Path_1135-3"
          data-name="Path 1135"
          d="M103.141,266.667h17.21c3.944,0,7.141,2.559,7.141,5.714v5H96v-5C96,269.226,99.2,266.667,103.141,266.667Z"
          transform="translate(-93.434 -248.453)"
          fill="#33cafb"
        />
        <path
          id="Path_1136-2"
          data-name="Path 1136"
          d="M104.929,266.667h-3.214A5.715,5.715,0,0,0,96,272.381v5h3.214v-5A5.715,5.715,0,0,1,104.929,266.667Z"
          transform="translate(-94.929 -248.453)"
          fill="#00bdfa"
        />
        <path
          id="Path_1145-2"
          data-name="Path 1145"
          d="M149.365,99.619a7.143,7.143,0,1,1,7.143-7.143A7.151,7.151,0,0,1,149.365,99.619Zm0-12.143a5,5,0,1,0,5,5A5.005,5.005,0,0,0,149.365,87.476Z"
          transform="translate(-136.508 -85.333)"
        />
        <path
          id="Path_1146-2"
          data-name="Path 1146"
          d="M99.619,268.857H86.4a1.072,1.072,0,0,1-1.071-1.071v-5A6.794,6.794,0,0,1,92.119,256h12.143v2.143H92.119a4.648,4.648,0,0,0-4.643,4.643v3.929H99.619Z"
          transform="translate(-85.333 -238.857)"
        />
      </g>
      <g
        id="Group_307"
        data-name="Group 307"
        transform="translate(108.212 85.333)"
      >
        <ellipse
          id="Ellipse_2-3"
          data-name="Ellipse 2"
          cx="6.071"
          cy="6.071"
          rx="6.071"
          ry="6.071"
          transform="translate(1.071 1.071)"
          fill="#fff"
        />
        <path
          id="Path_1134-3"
          data-name="Path 1134"
          d="M157.353,102.071a6.054,6.054,0,0,0-4.464-5.828A5.983,5.983,0,0,1,154.5,96a6.071,6.071,0,0,1,0,12.143,5.983,5.983,0,0,1-1.607-.243A6.054,6.054,0,0,0,157.353,102.071Z"
          transform="translate(-147.353 -94.929)"
          fill="#d5dbe1"
        />
        <path
          id="Path_1136-3"
          data-name="Path 1136"
          d="M96,266.667h3.214a5.715,5.715,0,0,1,5.714,5.714v5h-3.214v-5A5.715,5.715,0,0,0,96,266.667Z"
          transform="translate(-86 -248.453)"
          fill="#00bdfa"
        />
        <path
          id="Path_1145-3"
          data-name="Path 1145"
          d="M149.365,99.619a7.143,7.143,0,1,0-7.143-7.143A7.151,7.151,0,0,0,149.365,99.619Zm0-12.143a5,5,0,1,1-5,5A5.005,5.005,0,0,1,149.365,87.476Z"
          transform="translate(-142.222 -85.333)"
        />
        <path
          id="Path_1146-3"
          data-name="Path 1146"
          d="M89.976,268.857H103.19a1.072,1.072,0,0,0,1.071-1.071v-5A6.794,6.794,0,0,0,97.476,256H85.333v2.143H97.476a4.648,4.648,0,0,1,4.643,4.643v3.928H89.976Z"
          transform="translate(-84.262 -238.857)"
        />
      </g>
    </g>
  </svg>
);

export default Users;
