import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { IconAlert } from '../Icons';

import { INVALID_INPUT } from '../../../constants/notification-messages';

import './textarea.scss';

const Textarea = ({
  validation,
  name,
  label,
  required,
  numberOfRows = 14,
  placeholder,
  isEditing = true,
}) => {
  const [charCount, setCharCount] = useState(0);
  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Check and truncate value if it exceeds maxLength
    if (validation?.maxLength && value.length > validation.maxLength) {
      value = value.slice(0, validation.maxLength);
    }

    setCharCount(value.length);
    e.target.value = value; // Set the truncated value back to the input

    if (value.length >= validation?.maxLength) {
      setError(name, {
        type: 'manual',
        message: INVALID_INPUT.COMMENT_LENGTH,
      });
    } else {
      clearErrors(name);
    }
  };

  return (
    <div data-testid="text-area" className="textarea">
      <label
        data-testid="text-area-label"
        className="textarea__label"
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        {...register(name, {
          pattern: {
            value: validation?.pattern,
            message: validation?.patternMessage,
          },
          minLength: {
            value: validation?.minLength,
            message: validation?.minLengthMessage,
          },
          maxLength: {
            value: validation?.maxLength,
            message: validation?.maxLengthMessage,
          },
        })}
        data-testid="text-area-input"
        className="textarea__input"
        id={name}
        name={name}
        required={required}
        placeholder={placeholder}
        rows={numberOfRows}
        disabled={!isEditing}
        onChange={handleInputChange}
      />
      <div className="textarea__footer">
        {errors[name] && (
          <p
            id="error-message"
            className="a-input-field__error-message"
            data-testid="error-message"
          >
            <IconAlert />
            {errors[name]?.message}
          </p>
        )}
        {/* Character Counter */}
        <div className="textarea__char-count">
          {charCount}/{validation?.maxLength}
        </div>
      </div>
    </div>
  );
};

export default Textarea;
