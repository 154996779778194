import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  isAdmin,
  currentUser,
} from '../../../store/currentUser/currentUser.selector';
import { selectedUser } from '../../../store/users/users.selector';
import {
  deleteUserAsync,
  getUserAsync,
} from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Image';
import Modal from '../../molecules/Modal/Modal';

import './user.scss';

const User = () => {
  const visitedUser = useSelector(selectedUser);
  const { userId } = useParams();
  const admin = useSelector(isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currUser = useSelector(currentUser).userDto;
  const [showModal, setShowModal] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  const personal = Number(userId) === currUser.id;

  useEffect(() => {
    admin && dispatch(getUserAsync(userId));
  }, [userId]); // eslint-disable-line

  useEffect(() => {
    if (visitedUser?.imageDto) {
      fetch(`/api/image/data/${visitedUser.imageDto.id}`)
        .then((response) => response.blob())
        .then((blob) => {
          setProfileImage(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [visitedUser?.imageDto]); // eslint-disable-line

  const deleteUser = () => {
    dispatch(deleteUserAsync(userId, navigate));
    setShowModal(false);
  };

  return (
    <div className="row p-user">
      <div className="col__desktop-8 p-user__wrapper">
        <div className="row  p-user__row">
          <div className="col__desktop-5 col__laptop-6 p-user__action-wrapper">
            <Image
              key={currUser.id}
              className="large p-user__action-wrapper__image"
              src={profileImage}
            />
            <>
              <div className="p-user__action-wrapper__button">
                <Link to={`/edit-profile/${userId}`}>
                  <Button
                    text="edit profile"
                    classes="hover-bg--black slim large"
                  />
                </Link>
                {admin && (
                  <Button
                    onClick={() => setShowModal(true)}
                    text="remove user"
                    classes="bg--black hover-bg--red slim large"
                  />
                )}
              </div>
            </>
          </div>
          <div className="col__desktop-7 col__laptop-6 p-user__info-wrapper">
            <h1 className="p-user__info-wrapper__heading">
              {personal ? currUser.firstName : visitedUser?.firstName}
            </h1>
            <p className="p-user__info-wrapper__blue-paragraph">
              {personal ? currUser.email : visitedUser?.email}
            </p>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="p-user__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title="The user will be removed. Are you sure ?"
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={deleteUser}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default User;
