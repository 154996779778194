import React from 'react';

import { IconClose } from '../../atoms/Icons';

import './modal.scss';

const Modal = ({
  isOrderModal = false,
  showModal,
  setShowModal,
  hasBorder = false,
  hasBanner = true,
  hasBannerAlt = false,
  title,
  children,
  disclaimer,
  hasDisclaimerAlt = false,
}) => {
  const modalClass = isOrderModal ? 'm-order' : 'm-modal';
  return (
    <div className="m-modal-container" data-testid="modal-container">
      <div
        className={`${modalClass} ${
          hasBorder ? `${modalClass}--has-border` : ''
        } ${showModal ? 'visible' : ''}`}
      >
        <div className={`${modalClass}-header`}>
          {hasBanner && (
            <div
              className={`${modalClass}-header__banner ${
                hasBannerAlt ? `${modalClass}-header__banner--is-blue` : ''
              }`}
            >
              <span
                className={`${modalClass}-header__icon-container`}
                onClick={() => setShowModal(false)}
              >
                <IconClose
                  iconColor={`${hasBannerAlt ? 'white' : '#00bdfa'}`}
                  hasCircle={true}
                />
              </span>
            </div>
          )}
          {title && <h3 className={`${modalClass}-header__title`}>{title}</h3>}
        </div>

        <div className={`${modalClass}-body`}>{children}</div>

        <div className={`${modalClass}-footer`}>
          {disclaimer && (
            <div
              className={`${modalClass}-footer__disclaimer ${
                hasDisclaimerAlt
                  ? `${modalClass}-footer__disclaimer--has-alt`
                  : ''
              }`}
            >
              <p className="disclaimer">{disclaimer}</p>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${modalClass}__backdrop`}
        onClick={() => setShowModal(false)}
      ></div>
    </div>
  );
};

export default Modal;
