import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { REGEX_INPUT } from '../../../constants/regex-patterns';
import {
  locations,
  selectedRestaurant,
} from '../../../store/restaurants/restaurants.selector';
import {
  createRestaurantAsync,
  editRestaurantAsync,
  getLocationsAsync,
} from '../../../store/restaurants/restaurants.thunk';
import { week } from '../../../utils/options';
import {
  formatCamelcase,
  validateWorkdays,
  deliveryLocationHelpers,
  populateRestaurantDefaultValues,
  populateDeliveryLocations,
  handleDeliveryLocations,
} from '../../../utils/restaurantHelperFunctions';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Input from '../../atoms/Input/Input';
import InputNumber from '../../atoms/InputNumber/InputNumber';
import CheckboxGroup from '../../molecules/CheckboxGroup/CheckboxGroup';
import EditableTextarea from '../../molecules/EditableTextarea/EditableTextarea';
import Form from '../../molecules/Form/Form';

import './restaurantForm.scss';
import formInputs from './restaurantFormInputs.json';

const CreateRestaurant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSubmit(data) {
    const maxOrdersPerDay = Number(data.maxOrdersPerDay);
    if (isNaN(maxOrdersPerDay)) {
      console.error('Invalid value for Maximum Orders Per Day');
      return;
    }

    if (!deliveryLocationHelpers.validate(deliveryLocationIndexes, dispatch))
      return;
    if (!validateWorkdays(data.workingDays, dispatch)) return;

    if (restaurantId) {
      dispatch(
        editRestaurantAsync(
          restaurantId,
          data,
          deliveryLocationIndexes,
          navigate
        )
      );
    } else {
      dispatch(createRestaurantAsync(data, deliveryLocationIndexes, navigate));
    }
  }

  const { restaurantId } = useParams();
  const restaurant = useSelector(selectedRestaurant);
  const defaultValues = populateRestaurantDefaultValues(
    restaurantId,
    restaurant
  );

  const allLocations = useSelector(locations);
  const [deliveryLocationIndexes, setDeliveryLocationIndexes] = useState(
    populateDeliveryLocations(restaurant)
  );

  useEffect(() => {
    dispatch(getLocationsAsync());
  }, []); // eslint-disable-line

  return (
    <div className="form-container">
      <Form
        title={`${restaurantId ? 'Edit Restaurant' : 'Create Restaurant'}`}
        id="create-restaurant"
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      >
        <div className="create-restaurant">
          <div className="form__inputs left">
            {formInputs.textInputs.map((input, index) => (
              <Input
                key={index}
                className="long-black"
                id={input.name}
                name={input.name}
                placeholder={input.placeholder}
                type="text"
                validation={{
                  pattern: REGEX_INPUT[input.name.toUpperCase()],
                  patternMessage: `${INVALID_INPUT[input.name.toUpperCase()]}`,
                  maxLength: 100,
                  maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
                }}
              />
            ))}

            <CheckboxGroup title="Delivery Locations">
              {allLocations.map((location) => (
                <Checkbox
                  key={location.id}
                  id={location.id}
                  label={location.locationName}
                  name={`locations.${location.locationName}`}
                  onClick={(location) => {
                    handleDeliveryLocations(
                      location,
                      deliveryLocationIndexes,
                      setDeliveryLocationIndexes
                    );
                  }}
                />
              ))}
            </CheckboxGroup>

            <CheckboxGroup title="Workdays">
              {week.map((workday, index) => (
                <Checkbox
                  key={index}
                  label={workday.label}
                  name={`workingDays.${workday.name}`}
                />
              ))}
            </CheckboxGroup>

            <Input
              className="long-black"
              id="costForEmployees"
              name="costForEmployees"
              validation={{
                pattern: /^[0-9]*$/,
                patternMessage: 'Please use a number value',
              }}
              placeholder="Employees cost"
              type="text"
            />

            <Input
              className="long-black"
              id="maxOrdersPerDay"
              name="maxOrdersPerDay"
              validation={{
                pattern: /^(-?[0-9]+)$/,
                patternMessage: 'Please enter a valid number',
              }}
              placeholder="Maximum Orders Per Day"
              type="text"
            />

            {formInputs.numberInputs.map((name, index) => (
              <InputNumber
                key={index}
                name={name}
                title={formatCamelcase(name)}
                validation={{
                  pattern: REGEX_INPUT['TIME'],
                  patternMessage: INVALID_INPUT['TIME'],
                }}
              />
            ))}

            <div className="buttons">
              <Button
                type="submit"
                text="next"
                hoverText="add menu"
                classes="bg--black hover-bg--green"
              />
              <Button
                type="button"
                onClick={() => {
                  restaurantId ? navigate(-1) : navigate('/restaurant');
                }}
                icon="close"
                hoverIcon="close"
                classes="bg--black hover-bg--red small"
              />
            </div>
          </div>

          <div className="right">
            <EditableTextarea
              name="termsAndConditions"
              label="Terms & Conditions"
              numberOfRows={28}
              maxLength={2000}
              maxLengthMessage={INVALID_INPUT.TERMS_AND_CONDITIONS}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateRestaurant;
