import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ResetForm } from '../../../store/core.selector';

const Form = ({
  className,
  title,
  onSubmit,
  defaultValues,
  children,
  inputError = [],
  id,
}) => {
  const methods = useForm({ mode: 'onSubmit', defaultValues });
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = methods;
  const resetForm = ResetForm();

  useEffect(() => {
    inputError.forEach((inputError) =>
      setError(inputError.name, {
        type: 'custom',
        message: inputError.message,
      })
    );

    if (inputError.length) {
      const subscription = watch(() => clearErrors());
      return () => subscription.unsubscribe();
    }
  }, [inputError]); // eslint-disable-line

  useEffect(() => {
    if (resetForm) {
      reset();
    }
  }, [resetForm]); //eslint-disable-line

  return (
    <>
      {title && <h1 data-testid="h1">{title}</h1>}
      <FormProvider {...methods}>
        <form id={id} className={className} onSubmit={handleSubmit(onSubmit)}>
          {Array.isArray(children)
            ? children.map((child) => {
                if (child?.props?.name) {
                  return React.createElement(child.type, {
                    ...{
                      ...child.props,
                      errors,
                      register,
                      setValue,
                      key: child.props.name,
                    },
                  });
                }
                return child;
              })
            : children}
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
