import { useState } from 'react';

import { INVALID_PASSWORD } from '../../../constants/notification-messages';
import { REGEX_PASSWORD } from '../../../constants/regex-patterns';
import { IconShow, IconHide } from '../../atoms/Icons';
import Input from '../../atoms/Input/Input';

import './inputPassword.scss';

const InputPassword = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const togglePassword = () => {
    setIsVisible(!isVisible);
  };

  const toggleShowHide = (
    <div data-testid="svg" className="show-hide" onClick={togglePassword}>
      {isVisible ? <IconShow /> : <IconHide />}
    </div>
  );

  return (
    <Input
      className="outlined"
      type={isVisible ? 'text' : 'password'}
      title="Must contain at least one number, one uppercase and lowercase letter, one special character and at least 8 to 30 characters"
      required
      validation={{
        pattern: REGEX_PASSWORD,
        patternMessage: INVALID_PASSWORD.MISMATCH,
        minLength: 8,
        minLengthMessage: INVALID_PASSWORD.SHORT,
        maxLength: 30,
        maxLengthMessage: INVALID_PASSWORD.LONG,
      }}
      icon={toggleShowHide}
      {...props}
    />
  );
};

export default InputPassword;
