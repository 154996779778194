import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { REGEX_INPUT } from '../../../constants/regex-patterns';
import { error } from '../../../store/currentUser/currentUser.selector';
import { loginUserAsync } from '../../../store/currentUser/currentUser.thunk';
import logoImage from '../../../svg/valtech-gourmet-logo.svg';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Form from '../../molecules/Form/Form';
import InputPassword from '../../molecules/InputPassword/InputPassword';
import NewAccountRequest from '../../molecules/NewAccountRequest/NewAccountRequest';

import './login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const logInErrors = useSelector(error);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [inputError, setInputError] = useState([]);

  useEffect(() => {
    if (logInErrors) {
      setInputError([
        { name: 'username', message: logInErrors },
        { name: 'password', message: logInErrors },
      ]);
    }
  }, [logInErrors]);

  const handleLogin = (data) => {
    const { username, password } = data;
    dispatch(loginUserAsync(username, password));
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <img src={logoImage} alt="Valtech logo" />
          <Form
            inputError={inputError}
            className="form"
            title="Feed yourself. Log in"
            onSubmit={handleLogin}
          >
            <Input
              className="standard"
              id="username"
              label="username"
              name="username"
              required
              type="email"
              validation={{
                pattern: REGEX_INPUT.VALTECH_MAIL,
                patternMessage: INVALID_INPUT.VALTECH_MAIL,
              }}
              title={INVALID_INPUT.VALTECH_MAIL}
              autoComplete="username"
              dataTestId="username-input-element"
            />
            <InputPassword
              id="password"
              label="password"
              name="password"
              placeholder="Must be at least 8 characters"
              autoComplete="current-password"
              dataTestId="password-input-element"
            />
            <Button
              icon="arrow-right"
              hoverText="log in"
              classes="hover-bg--black"
              type="submit"
              data-testid="login-button"
            />
          </Form>
          <div className="form__actions">
            <Link to="/forgot-password">Forgot password?</Link>
            <p>
              Don’t have an account?
              <button onClick={() => setModalIsShown(true)}>Request One</button>
            </p>
          </div>
        </div>
      </div>
      <NewAccountRequest
        showModal={modalIsShown}
        setShowModal={setModalIsShown}
      />
    </>
  );
};

export default Login;
