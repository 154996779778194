import React from 'react';

import './userSearch.scss';

function UserSearch({ searchText, setSearchText, company, setCompany }) {
  return (
    <div className="user-search-container">
      <div className="user-search-container__filters row row__large mq-laptop-helper">
        <div className="user-search-container__filters__input ">
          <label data-testid="select-label-element">Search</label>
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            value={searchText}
            placeholder="Search..."
          />
        </div>
        <div className="user-search-container__filters__select">
          <div className="user-search-container__filters__select__input">
            <label data-testid="select-label-element">Company</label>
            <select
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            >
              <option value={''} selected>
                Company..
              </option>
              <option key="KinCarta" value="KinCarta">
                KinCarta
              </option>
              <option key="Valtech" value="Valtech">
                Valtech
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSearch;
