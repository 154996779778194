import { NOTIFICATION_ACTION_TYPES } from './notification.types';

export const INITIAL_STATE = [];

export const notificationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_ACTION_TYPES.PROCESS:
      return [...payload];
    case NOTIFICATION_ACTION_TYPES.HIDE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
