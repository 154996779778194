import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import Button from '../../atoms/Button/Button';
import Textarea from '../../atoms/Textarea/Textarea';

const EditableTextarea = ({
  name,
  label,
  required,
  numberOfRows = 14,
  maxLength = 100,
  maxLengthMessage = INVALID_INPUT['GENERAL_LENGTH'],
}) => {
  const { setFocus } = useFormContext();

  const [editing, setEditing] = useState(false);

  function toggleEditing() {
    setEditing((prev) => !prev);
    setTimeout(() => {
      setFocus(name);
    }, 10);
  }

  return (
    <>
      <Textarea
        name={name}
        isEditing={editing}
        numberOfRows={numberOfRows}
        label={label}
        required={required}
        validation={{
          maxLength: maxLength,
          maxLengthMessage: maxLengthMessage,
        }}
      />
      <Button
        text={editing ? 'save changes' : 'edit'}
        classes={`slim large bg--${editing ? 'black' : 'white'}`}
        onClick={toggleEditing}
      />
    </>
  );
};

export default EditableTextarea;
