export const restaurants = (state) => state.restaurants.restaurants;
export const isLoading = (state) => state.restaurants.isLoading;
export const selectedRestaurant = (state) =>
  state.restaurants.selectedRestaurant;
export const categories = (state) => state.restaurants.categories;
export const locations = (state) => state.restaurants.locations;
export const isCreatedMenuItemSuccessfully = (state) =>
  state.restaurants.createdMenuItemSuccessfully;
export const menuItems = (state) => state.restaurants.menuItems;
export const selectedMenuItem = (state) => state.restaurants.selectedMenuItem;
export const menus = (state) => state.restaurants.menus;
export const selectedMenuId = (state) => state.restaurants.selectedMenuId;
export const restaurantsForDate = (state) =>
  state.restaurants.restaurantsForDate;
export const limitForOrders = (state) => state.restaurants.limitForOrders;
export const termsAndConditionsStatus = (state) =>
  state.restaurants.termsAndConditionsAccepted;
