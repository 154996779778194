import { createAction } from '@reduxjs/toolkit';

import { USER_ACTION_TYPES } from './currentUser.types';

//USING REDUX THUNK
//fetchUserStart only needs to set the loading state to true, to tell the reducer that the start of fetching data has begun.
export const loginUserStart = () => {
  return createAction(USER_ACTION_TYPES.FETCH_LOG_IN_USER_START);
};
//fetchUserSuccess passes the user as payload
export const storeUserDetails = (user) => {
  return createAction(USER_ACTION_TYPES.FETCH_LOG_IN_USER_SUCCESS, () => {
    return { payload: user };
  });
};

//fetchUserFailed passes the error as payload
export const loginUserFailed = (error) => {
  return createAction(USER_ACTION_TYPES.FETCH_LOG_IN_USER_FAILED, () => {
    return { payload: error };
  });
};

export const logoutUserSuccess = () => {
  return createAction(USER_ACTION_TYPES.FETCH_LOG_OUT_USER_SUCCESS);
};

export const actionStart = () => {
  return createAction(USER_ACTION_TYPES.FETCH_ACTION_START);
};

export const actionEnd = () => {
  return createAction(USER_ACTION_TYPES.FETCH_ACTION_END);
};

export const successfullyRegistered = () => {
  return createAction(USER_ACTION_TYPES.FETCH_REGISTERED_SUCCESSFULLY);
};

export const removeUser = () => {
  return createAction(USER_ACTION_TYPES.FETCH_REMOVE_USER);
};
