import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RefreshTokenModal from '../components/molecules/RefreshTokenModal/RefreshTokenModal';
import { SESSION } from '../constants/notification-messages';
import { removeUser } from '../store/currentUser/currentUser.action';
import { currentUser } from '../store/currentUser/currentUser.selector';
import { handleNotificationAction } from '../store/notification/notification.action';

const RefreshAndRemoveToken = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const removeToken = useCallback(() => {
    const token = localStorage.getItem('access_token');

    if (!token) return;

    // Decode the token to get the expiry time
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = decodedToken.exp;

    // Check if the token has expired
    if (expiryTime * 1000 < Date.now()) {
      // Remove the token from local storage
      localStorage.removeItem('access_token');
      dispatch(removeUser()());
      dispatch(
        handleNotificationAction({
          title: SESSION.EXPIRED,
          status: 'error',
        })()
      );
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        removeToken();
      }, 1800000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [user, removeToken]);

  return <RefreshTokenModal />;
};

export default RefreshAndRemoveToken;
