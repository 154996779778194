const Profile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 27.717 30"
  >
    <g id="icon-profile" transform="translate(-85.333 -85.333)">
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="5.484"
        cy="5.484"
        r="5.484"
        transform="translate(91.462 86.301)"
        fill="#fff"
      />
      <path
        id="Path_1134"
        data-name="Path 1134"
        d="M155.792,101.484a5.468,5.468,0,0,1,4.032-5.264A5.4,5.4,0,0,0,158.373,96a5.484,5.484,0,0,0,0,10.968,5.4,5.4,0,0,0,1.452-.219A5.468,5.468,0,0,1,155.792,101.484Z"
        transform="translate(-61.427 -9.699)"
        fill="#d5dbe1"
      />
      <path
        id="Path_1135"
        data-name="Path 1135"
        d="M113.6,266.667H101.161A5.162,5.162,0,0,0,96,271.828v4.516h22.761v-4.516A5.162,5.162,0,0,0,113.6,266.667Z"
        transform="translate(-9.699 -164.882)"
        fill="#fff"
      />
      <path
        id="Path_1136"
        data-name="Path 1136"
        d="M104.065,266.667h-2.9A5.162,5.162,0,0,0,96,271.828v4.516h2.9v-4.516A5.162,5.162,0,0,1,104.065,266.667Z"
        transform="translate(-9.699 -164.882)"
        fill="#d5dbe1"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="3.548"
        cy="3.548"
        r="3.548"
        transform="translate(103.72 105.656)"
        fill="#33cafb"
      />
      <path
        id="Path_1137"
        data-name="Path 1137"
        d="M290.9,312.881a3.543,3.543,0,0,1,2.1-3.231,3.548,3.548,0,1,0,0,6.462A3.543,3.543,0,0,1,290.9,312.881Z"
        transform="translate(-184.28 -203.677)"
        fill="#00bdfa"
      />
      <path
        id="Path_1138"
        data-name="Path 1138"
        d="M281.849,307.7a4.516,4.516,0,1,1,4.516-4.516A4.521,4.521,0,0,1,281.849,307.7Zm0-7.1a2.581,2.581,0,1,0,2.581,2.581A2.583,2.583,0,0,0,281.849,300.6Z"
        transform="translate(-174.581 -193.979)"
      />
      <path
        id="Path_1139"
        data-name="Path 1139"
        d="M316.444,387.556h1.936v2.581h-1.936Z"
        transform="translate(-210.143 -274.804)"
      />
      <path
        id="Path_1140"
        data-name="Path 1140"
        d="M316.444,280.889h1.936v2.581h-1.936Z"
        transform="translate(-210.143 -177.814)"
      />
      <path
        id="Path_1141"
        data-name="Path 1141"
        d="M355.556,307.1l2.233-1.289.968,1.676-2.233,1.289Z"
        transform="translate(-245.707 -200.471)"
      />
      <path
        id="Path_1142"
        data-name="Path 1142"
        d="M263.192,360.257l2.233-1.289.968,1.676-2.233,1.289Z"
        transform="translate(-161.723 -248.809)"
      />
      <path
        id="Path_1143"
        data-name="Path 1143"
        d="M263.267,306.973l.968-1.676,2.233,1.289-.968,1.676Z"
        transform="translate(-161.791 -200.008)"
      />
      <path
        id="Path_1144"
        data-name="Path 1144"
        d="M355.538,360.116l.968-1.676,2.233,1.289-.968,1.676Z"
        transform="translate(-245.69 -248.329)"
      />
      <path
        id="Path_1145"
        data-name="Path 1145"
        d="M148.674,98.236a6.452,6.452,0,1,1,6.452-6.452A6.459,6.459,0,0,1,148.674,98.236Zm0-10.968a4.516,4.516,0,1,0,4.516,4.516A4.521,4.521,0,0,0,148.674,87.269Z"
        transform="translate(-51.728)"
      />
      <path
        id="Path_1146"
        data-name="Path 1146"
        d="M98.236,267.613H86.3a.968.968,0,0,1-.968-.968v-4.516A6.136,6.136,0,0,1,91.462,256H102.43v1.935H91.462a4.2,4.2,0,0,0-4.194,4.194v3.548H98.236Z"
        transform="translate(0 -155.183)"
      />
    </g>
  </svg>
);

export default Profile;
