const Close = ({ hasCircle, iconColor, iconSize = '25px' }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0625 9L9 11.0625L12.4375 14.5L9 17.9375L11.0625 20L14.5 16.5625L17.9375 20L20 17.9375L16.5625 14.5L20 11.0625L17.9375 9L14.5 12.4375L11.0625 9Z"
        strokeWidth={hasCircle && '.5'}
        stroke={hasCircle && 'white'}
        fill={iconColor}
      />
      {hasCircle && (
        <circle cx="14.5" cy="14.5" r="12" stroke={iconColor} strokeWidth="2" />
      )}
    </svg>
  );
};

export default Close;
