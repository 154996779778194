import { createAction } from '@reduxjs/toolkit';

import { ALL_USERS_ACTION_TYPES } from './users.types';

export const allUsersStart = () => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_ALL_USERS_START);
};

export const allUsersSuccess = (allUsers) => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_ALL_USERS_SUCCESS, () => {
    return { payload: allUsers };
  });
};

export const updatedAllUsers = (allUsers) => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_UPDATED_ALL_USERS, () => {
    return { payload: allUsers };
  });
};

export const accountRequestsSuccess = (accountRequests) => {
  return createAction(
    ALL_USERS_ACTION_TYPES.FETCH_ACCOUNT_REQUESTS_SUCCESS,
    () => {
      return { payload: accountRequests };
    }
  );
};

export const selectedUserStart = () => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_SELECTED_USER_START);
};

export const selectedUserSuccess = (selectedUser) => {
  return createAction(
    ALL_USERS_ACTION_TYPES.FETCH_SELECTED_USER_SUCCESS,
    () => {
      return { payload: selectedUser };
    }
  );
};

export const successfullyCreated = () => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_CREATED_USER_SUCCESSFULLY);
};

export const actionStart = () => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_ACTION_START);
};

export const actionEnd = () => {
  return createAction(ALL_USERS_ACTION_TYPES.FETCH_ACTION_END);
};
