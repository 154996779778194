import { handleNotificationAction } from '../notification/notification.action';

import {
  CANCEL_ORDER,
  CREATE_ORDER,
  DECLINED_REQUEST,
} from '../../constants/notification-messages';
import OrderService from '../../services/order.service';

import {
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersEnd,
  fetchCurrentUserOrdersSuccess,
  cancelUserOrderStart,
  cancelUserOrderEnd,
} from './orders.actions';

export const fetchOrdersAsync = () => async (dispatch) => {
  dispatch(fetchOrdersStart()());

  try {
    const response = await OrderService.getAllUserOrders();
    if (response.status === 200) {
      dispatch(fetchOrdersSuccess(response.data || [])());
    } else {
      dispatch(
        handleNotificationAction(
          { title: DECLINED_REQUEST.ERROR_MESSAGE, status: 'error' }()
        )
      );
    }
  } catch {
    dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.ALL_ORDERS,
        status: 'error',
      })()
    );
  } finally {
    dispatch(fetchOrdersEnd()());
  }
};

export const fetchOrdersPaginatedAsync =
  (ordersPerPage,dateFrom,dateTo,restaurantId,userId,company,status) => async (dispatch) => {
    dispatch(fetchOrdersStart()());

    try {
      const response = await OrderService.getAllUserOrdersPaginated(
        ordersPerPage,
          12,
          dateFrom,
          dateTo,
          restaurantId,
          userId,
          company,
          status
      );
      if (response.status === 200) {
        dispatch(fetchOrdersSuccess(response.data || [])());
      } else {
        dispatch(
          handleNotificationAction(
            { title: DECLINED_REQUEST.ERROR_MESSAGE, status: 'error' }()
          )
        );
      }
    } catch {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.ALL_ORDERS,
          status: 'error',
        })()
      );
    } finally {
      dispatch(fetchOrdersEnd()());
    }
  };

export const createOrderAsync = (order, navigate) => async (dispatch) => {
  dispatch(fetchOrdersStart()());

  try {
    await OrderService.createOrder(order);
    dispatch(handleNotificationAction({ title: CREATE_ORDER.SUCCESS })());
    navigate('/');
  } catch (error) {
    const errorMessage = error.response.data.message;
    dispatch(
      handleNotificationAction({
        title: errorMessage || CREATE_ORDER.ERROR,
        status: 'error',
      })()
    );
  } finally {
    dispatch(fetchOrdersEnd()());
  }
};

export const fetchCurrentUserOrders = (userId) => async (dispatch) => {
  dispatch(fetchOrdersStart()());

  try {
    const response = await OrderService.getCurrentUserOrders(userId);
    if (response.status === 200) {
      dispatch(fetchCurrentUserOrdersSuccess(response.data || [])());
    } else {
      dispatch(
        handleNotificationAction(
          { title: DECLINED_REQUEST.ERROR_MESSAGE, status: 'error' }()
        )
      );
    }
  } catch {
    dispatch(
      handleNotificationAction({
        title: DECLINED_REQUEST.ALL_ORDERS,
        status: 'error',
      })()
    );
  } finally {
    dispatch(fetchOrdersEnd()());
  }
};

export const cancelUserOrder = (userOrderId) => async (dispatch) => {
  dispatch(cancelUserOrderStart()());

  try {
    await OrderService.cancelOrder(userOrderId);
    dispatch(handleNotificationAction({ title: CANCEL_ORDER.SUCCESS })());
    return true;
  } catch (error) {
    const errorMessage = error.response.data.message;
    dispatch(
      handleNotificationAction({
        title: errorMessage || CANCEL_ORDER.ERROR,
        status: 'error',
      })()
    );
    return false;
  } finally {
    dispatch(cancelUserOrderEnd()());
  }
};
