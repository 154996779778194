import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { notification } from '../../../store/notification/notification.selector';
import {
  categories,
  menuItems,
  selectedMenuItem,
  selectedRestaurant,
} from '../../../store/restaurants/restaurants.selector';
import {
  createMenuItemAsync,
  editMenuItemAsync,
} from '../../../store/restaurants/restaurants.thunk';
import { menuItemTypes, week } from '../../../utils/options';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Input from '../../atoms/Input/Input';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Textarea from '../../atoms/Textarea/Textarea';
import CheckboxGroup from '../../molecules/CheckboxGroup/CheckboxGroup';
import Form from '../../molecules/Form/Form';

import { mainMenuList } from './filterFunctions';
import './menuItemForm.scss';

const MenuItemForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuItemId } = useParams();
  const notifications = useSelector(notification);
  const allCategories = useSelector(categories);
  const restaurantId = useSelector(selectedRestaurant).id;
  const menuId = useSelector(selectedRestaurant).menuId;
  const menuItem = useSelector(selectedMenuItem);
  const allMenuItems = useSelector(menuItems);
  const [typeValue, setTypeValue] = useState(
    menuItemId ? menuItem.type : 'MAIN'
  );

  useEffect(() => {
    menuItemId &&
      notifications.status === 'success' &&
      navigate(`/restaurant/${restaurantId}`);
  }, [notifications.status]); //eslint-disable-line

  const handleSubmit = (data) => {
    const { categoryIds, parentMenuItems, ...restData } = data;

    const convertedCategoryIds = allCategories
      .filter((category) => categoryIds?.[category.name])
      .map((category) => category.id);

    const modifiedData =
      typeValue === 'SIDE'
        ? {
            ...restData,
            parentMenuItems: allMenuItems
              .filter((menuItem) => parentMenuItems[menuItem.name])
              .map((menuItem) => menuItem.menuItemId),
            costForEmployees: null,
            categoryIds: null,
            workingDays: null,
          }
        : {
            ...restData,
            categoryIds: convertedCategoryIds,
          };

    menuItemId
      ? dispatch(
          editMenuItemAsync(restaurantId, menuId, menuItemId, modifiedData)
        )
      : dispatch(
          createMenuItemAsync(restaurantId, menuId, allMenuItems, modifiedData)
        );
  };

  return (
    <div className="row">
      <div className="add-menu-item-form col__desktop-12 col__laptop-12 col__tablet-12 col__mobile-12">
        <Form
          defaultValues={
            menuItemId
              ? {
                  type: menuItem.type,
                  name: menuItem.name,
                  description: menuItem.description,
                  costForEmployees: menuItem.costForEmployees,
                  actualPrice: menuItem.actualPrice,
                  workingDays: menuItem.workingDays,
                  status: menuItem.status,
                  parentMenuItems: allMenuItems.reduce((acc, item) => {
                    acc[item.name] = menuItem.parentMenuItems.includes(
                      item.menuItemId
                    );
                    return acc;
                  }, {}),
                  categoryIds: allCategories.reduce((acc, category) => {
                    acc[category.name] = menuItem.categoryIds.includes(
                      category.id
                    );
                    return acc;
                  }, {}),
                }
              : {
                  workingDays: {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false,
                  },
                }
          }
          onSubmit={handleSubmit}
          title={`${menuItemId ? 'Edit' : 'Create'} Menu Item`}
        >
          <div className="add-menu-item-form__container">
            <div className="add-menu-item-form__wrapper">
              <SelectInput
                label="select type"
                name="type"
                options={menuItemTypes}
                onChange={(value) => setTypeValue(value)}
              />
              {typeValue === 'SIDE' && (
                <CheckboxGroup title="parent menu item">
                  {mainMenuList(allMenuItems).map((menuItem) => (
                    <Checkbox
                      key={menuItem.menuItemId}
                      label={menuItem.name}
                      name={`parentMenuItems.${menuItem.name}`}
                    />
                  ))}
                </CheckboxGroup>
              )}
              <Input
                id="name"
                name="name"
                className="long-black"
                placeholder="name"
                validation={{
                  maxLength: 100,
                  maxLengthMessage: INVALID_INPUT['GENERAL_LENGTH'],
                }}
                required
              />
              <Textarea
                name="description"
                required={false}
                label="description"
                numberOfRows={5}
                validation={{
                  maxLength: 500,
                  maxLengthMessage: INVALID_INPUT['MAX_LENGTH'],
                }}
              />
              {typeValue !== 'SIDE' && (
                <Input
                  id="costForEmployees"
                  name="costForEmployees"
                  className="long-black"
                  placeholder="employee price"
                  type="number"
                />
              )}
              <Input
                id="actualPrice"
                name="actualPrice"
                className="long-black"
                placeholder="actual price"
                required
                type="number"
              />
              {menuItemId && (
                <SelectInput
                  label="status"
                  name="status"
                  options={[
                    { value: 'ACTIVE', label: 'Active' },
                    { value: 'INACTIVE', label: 'Inactive' },
                  ]}
                />
              )}
            </div>
            <div className="add-menu-item-form__checkbox-wrapper">
              {typeValue !== 'SIDE' && (
                <>
                  <CheckboxGroup title="days available">
                    {week.map((day) => (
                      <Checkbox
                        key={day.name}
                        label={day.label}
                        name={`workingDays.${day.name}`}
                      />
                    ))}
                  </CheckboxGroup>
                  {!!allCategories.length && (
                    <CheckboxGroup title="food category">
                      {allCategories.map((category) => (
                        <Checkbox
                          key={category.name}
                          label={category.name}
                          name={`categoryIds.${category.name}`}
                        />
                      ))}
                    </CheckboxGroup>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="add-menu-item-form__button-wrapper">
            <Button
              classes="bg--black hover-bg--green"
              text="save"
              type="submit"
            />
            <Button
              onClick={() => navigate(`/restaurant/${restaurantId}`)}
              classes="bg--red hover-bg--black small"
              hoverIcon="close"
              icon="close"
              type="button"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MenuItemForm;
