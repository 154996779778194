import { useFormContext } from 'react-hook-form';

import ShortText from '../ShortText/ShortText';

import './checkbox.scss';

const Checkbox = ({ name, label, disabled = false, ...props }) => {
  const { register } = useFormContext();

  const labelValue = label ?? name;
  const correctedLabel =
    labelValue.charAt(0).toUpperCase() + labelValue.slice(1);

  return (
    <div className="checkbox">
      <label htmlFor={name} className="checkbox__label">
        <ShortText text={correctedLabel} maxLength={50}></ShortText>
      </label>
      <input
        {...register(name)}
        name={name}
        id={name}
        data-label={labelValue}
        className="checkbox__check"
        type="checkbox"
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default Checkbox;
