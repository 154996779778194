import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SET_PASSWORD } from '../../../constants/notification-messages';
import { handleNotificationAction } from '../../../store/notification/notification.action';
import { setPasswordAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import ErrorComponent from '../../molecules/ErrorComponent/ErrorComponent';
import Form from '../../molecules/Form/Form';
import InputPassword from '../../molecules/InputPassword/InputPassword';

import './setNewPassword.scss';

const SetNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get('token');

  if (!token) {
    return (
      <ErrorComponent
        errorType={500}
        errorTitle="Oops! Not a valid page"
        errorDesc="Sorry, the page does not contain a valid token. Please, make sure you have the correct URL."
      />
    );
  }

  const submitHandler = (data) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      dispatch(
        handleNotificationAction({
          title: 'The passwords entered do not match.',
          status: 'error',
        })()
      );
      return;
    }

    dispatch(setPasswordAsync(navigate, token, password));
  };

  const cancelButtonHandler = () => {
    dispatch(
      handleNotificationAction({
        title: SET_PASSWORD.ERROR,
        status: 'error',
      })()
    );
    navigate('/');
  };

  return (
    <div className="p-set-password">
      <div className="p-set-password__container">
        <Form
          className="p-set-password__container__form"
          title="Set new password"
          onSubmit={submitHandler}
        >
          <InputPassword
            id="password"
            label="enter new password"
            name="password"
            autoComplete="password"
            placeholder="Must be at least 8 characters"
          />
          <InputPassword
            id="confirmPassword"
            label="confirm password"
            name="confirmPassword"
            autoComplete="confirmPassword"
            placeholder="Must be at least 8 characters"
          />
          <div>
            <Button classes="bg--green" text="confirm" type="submit" />
            <Button
              onClick={cancelButtonHandler}
              classes="bg--red hover-bg--black small"
              hoverIcon="close"
              icon="close"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SetNewPassword;
