import { USER_ACTION_TYPES } from './currentUser.types';

export const INITIAL_STATE = {
  name: undefined,
  isLoading: false,
  error: null,
  successfullyRegistered: false,
};

export const currentUserReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.FETCH_LOG_IN_USER_START:
      return { ...state, isLoading: true, error: null };
    case USER_ACTION_TYPES.FETCH_LOG_IN_USER_SUCCESS:
      return { ...state, name: payload, isLoading: false };
    case USER_ACTION_TYPES.FETCH_LOG_IN_USER_FAILED:
      return { ...state, isLoading: false, error: payload };
    case USER_ACTION_TYPES.FETCH_LOG_OUT_USER_SUCCESS:
      return {
        state: INITIAL_STATE,
      };
    case USER_ACTION_TYPES.FETCH_ACTION_START:
      return { ...state, isLoading: true, successfullyRegistered: false };
    case USER_ACTION_TYPES.FETCH_ACTION_END:
      return { ...state, isLoading: false };
    case USER_ACTION_TYPES.FETCH_REGISTERED_SUCCESSFULLY:
      return { ...state, successfullyRegistered: true };
    case USER_ACTION_TYPES.FETCH_REMOVE_USER:
      return { ...state, name: undefined };
    default:
      return state;
  }
};
