export const ALL_USERS_ACTION_TYPES = {
  FETCH_ALL_USERS_START: 'FETCH_ALL_USERS_START',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',
  FETCH_UPDATED_ALL_USERS: 'FETCH_UPDATED_ALL_USERS',
  FETCH_ACCOUNT_REQUESTS_SUCCESS: 'FETCH_ACCOUNT_REQUESTS_SUCCESS',
  FETCH_SELECTED_USER_START: 'FETCH_SELECTED_USER_START',
  FETCH_SELECTED_USER_SUCCESS: 'FETCH_SELECTED_USER_SUCCESS',
  FETCH_CREATED_USER_SUCCESSFULLY: 'FETCH_CREATED_USER_SUCCESSFULLY',
  FETCH_ACTION_START: 'FETCH_ACTION_START',
  FETCH_ACTION_END: 'FETCH_ACTION_END',
};
