const Orders = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="40"
    viewBox="0 0 30.879 30"
  >
    <defs>
      <style>
        {`.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.5px;}`}
        {`.cls-2{fill:#00bdfa;stroke:#020202;stroke-miterlimit:10;stroke-width:1.5px;}`}
      </style>
    </defs>
    <polyline
      className="cls-1"
      points="11.04 20.59 1.5 20.59 1.5 1.5 16.77 1.5 16.77
    9.14"
    />
    <line className="cls-1" x1="4.36" y1="5.32" x2="6.27" y2="5.32" />
    <line className="cls-1" x1="8.18" y1="5.32" x2="13.91" y2="5.32" />
    <line className="cls-1" x1="4.36" y1="9.14" x2="6.27" y2="9.14" />
    <line className="cls-1" x1="8.18" y1="9.14" x2="13.91" y2="9.14" />
    <line className="cls-1" x1="4.36" y1="12.95" x2="6.27" y2="12.95" />
    <line className="cls-1" x1="8.18" y1="12.95" x2="13.91" y2="12.95" />
    <ellipse className="cls-2" cx="17.73" cy="18.68" rx="4.77" ry="3.82" />
    <path
      className="cls-2"
      d="M20.11,11h1.43a0,0,0,0,1,0,0v1.43a2.39,2.39,0,0,1-2.39,2.39H17.73a0,0,0,0,1,0,0V13.43A2.39,2.39,0,0,1,20.11,11Z"
    />
    <path className="cls-1" d="M14.86,11.05h0a2.86,2.86,0,0,1,2.87,2.86v.95" />
  </svg>
);

export default Orders;
