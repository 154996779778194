import { useDispatch, useSelector } from 'react-redux';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { setShouldSendEmail } from '../../../store/orders/orders.actions';
import { shouldSendEmail } from '../../../store/orders/orders.selector';
import { selectedRestaurant } from '../../../store/restaurants/restaurants.selector';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Textarea from '../../atoms/Textarea/Textarea';
import CheckboxGroup from '../../molecules/CheckboxGroup/CheckboxGroup';

const Step6 = () => {
  const sendMail = useSelector(shouldSendEmail);
  const dispatch = useDispatch();
  const restaurant = useSelector(selectedRestaurant);

  return (
    <center>
      {/*temporarily added only for Kazablanka*/}
      {restaurant.id === 7 && (
        <SelectInput
          label="Delivery Location"
          name="deliveryLocation"
          options={[
            { value: 'office', label: 'Office' },
            { value: 'restaurant', label: 'Restaurant' },
          ]}
        />
      )}
      <Textarea
        name="comment"
        numberOfRows={10}
        label="Order Comment"
        placeholder="For example... No ketchup for my order, no salt, I'm allergic to peanuts, etc..."
        required={false}
        validation={{
          maxLength: 200,
          maxLengthMessage: INVALID_INPUT['COMMENT_LENGTH'],
        }}
      />
      <CheckboxGroup>
        <Checkbox
          label="Do you want to receieve email notifications?"
          name="shouldSendEmail"
          checked={sendMail}
          onClick={() => dispatch(setShouldSendEmail(!sendMail)())}
        />
      </CheckboxGroup>
    </center>
  );
};

export default Step6;
