import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';

import {
  filteredExtraItems,
  filteredSideItems,
  filterMainMenuItemsByCategory,
  mainMenuList,
} from '../MenuItemForm/filterFunctions';

import { isAdmin } from '../../../store/currentUser/currentUser.selector';
import { fetchSelectedMenuId } from '../../../store/restaurants/restaurants.actions';
import {
  categories,
  menuItems,
  menus,
  selectedMenuId,
  selectedRestaurant,
} from '../../../store/restaurants/restaurants.selector';
import {
  getCategoriesAsync,
  getMenuItemsAsync,
  getMenusAsync,
  getRestaurantAsync,
  removeRestaurantAsync,
} from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Image';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import ErrorComponent from '../../molecules/ErrorComponent/ErrorComponent';
import Form from '../../molecules/Form/Form';
import MenuList from '../../molecules/MenuList/MenuList';
import Modal from '../../molecules/Modal/Modal';

import './restaurant.scss';

const Restaurant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const visitedRestaurant = useSelector(selectedRestaurant);
  const allMenuItems = useSelector(menuItems);
  const allMenus = useSelector(menus);
  const [showModal, setShowModal] = useState(false);
  const admin = useSelector(isAdmin);
  const foodCategories = useSelector(categories);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [mainMenuItems, setMainMenuItems] = useState(
    mainMenuList(allMenuItems)
  );
  const menuId = useSelector(selectedMenuId) || allMenus[0]?.id;

  useEffect(() => {
    if (menuId) {
      dispatch(getMenuItemsAsync(restaurantId, menuId));
    }
  }, [restaurantId, menuId]); // eslint-disable-line

  useEffect(() => {
    dispatch(getCategoriesAsync());
    dispatch(getMenusAsync(restaurantId));
    dispatch(getRestaurantAsync(restaurantId));
  }, [restaurantId]); // eslint-disable-line

  useEffect(() => {
    setMainMenuItems(
      selectedCategory === 0
        ? mainMenuList(allMenuItems)
        : filterMainMenuItemsByCategory(allMenuItems, selectedCategory)
    );
  }, [allMenuItems, selectedCategory]);

  const removeRestaurant = () => {
    dispatch(removeRestaurantAsync(restaurantId, navigate));
    setShowModal(false);
  };

  const handleCategoryClick = (categoryId) => {
    setMainMenuItems(filterMainMenuItemsByCategory(allMenuItems, categoryId));
    setSelectedCategory(categoryId);
  };

  const handleAllCategoriesClick = () => {
    setSelectedCategory(0);
    setMainMenuItems(mainMenuList(allMenuItems));
  };

  const filteredCategories = foodCategories.filter((category) =>
    allMenuItems.some((item) => item.categoryIds.includes(category.id))
  );

  if (!visitedRestaurant) {
    return (
      <ErrorComponent
        errorDesc="The server encountered an unexpected condition that prevented it from fulfilling the request."
        errorTitle="Unexpected Error :("
        errorType={500}
      />
    );
  }

  return (
    <div className="p-restaurant">
      <div className="row row__large helper">
        <Image className="large" />
        <div className=" p-restaurant__detail-wrapper">
          <h1>{visitedRestaurant.name}</h1>
          <label>{visitedRestaurant.email}</label>
          <h2>{visitedRestaurant.phonenumber}</h2>
        </div>
        {admin && (
          <div className="p-restaurant__action-wrapper">
            <Link to={`/restaurant/${restaurantId}/edit`}>
              <Button
                classes="bg--blue hover-bg--black slim large"
                hoverText="click to edit"
                text="edit restaurant"
              />
            </Link>
            <Button
              onClick={() => setShowModal(true)}
              classes="bg--white hover-bg--black slim large"
              hoverText="click to remove"
              text="remove restaurant"
            />
          </div>
        )}
      </div>
      <div className="p-restaurant__heading row">
        {admin && (
          <Button
            onClick={() => navigate('/create-menu-item')}
            classes="bg--white hover-bg--black slim small col__desktop-6 col__laptop-6 col__tablet-6 col__mobile-6"
            text="add item"
          />
        )}
        <p className="col__desktop-6 col__laptop-6 col__tablet-6 col__mobile-6">
          Menu
        </p>
      </div>
      {allMenus.length > 1 && (
        <div className="row row__large helper p-restaurant__dropdown-menu">
          <Form>
            <SelectInput
              label="select menu"
              name="Menus"
              options={allMenus.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              onChange={(value) => dispatch(fetchSelectedMenuId(value)())}
            />
          </Form>
        </div>
      )}
      <div className="row row__large p-restaurant__menu-wrapper">
        <div className="p-restaurant__categories">
          <h4
            className={selectedCategory === 0 ? 'active' : ''}
            onClick={handleAllCategoriesClick}
          >
            All
          </h4>
          {filteredCategories.map((category) => {
            return (
              <h4
                key={category.id}
                className={selectedCategory === category.id ? 'active' : ''}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.name}
              </h4>
            );
          })}
        </div>
        <div className="p-restaurant__foods">
          {mainMenuItems.length > 0 ? (
            <MenuList
              mainMenuList={mainMenuItems}
              extraMenuList={filteredSideItems(allMenuItems, mainMenuItems)}
              sideMenuList={filteredExtraItems(allMenuItems, mainMenuItems)}
            />
          ) : (
            <h4>No menu items found.</h4>
          )}
        </div>
      </div>
      {showModal && (
        <div className="p-restaurant__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title="The restaurant will be removed. Are you sure ?"
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={removeRestaurant}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Restaurant;
