export const USER_ACTION_TYPES = {
  FETCH_LOG_IN_USER_START: 'FETCH_LOG_IN_USER_START',
  FETCH_LOG_IN_USER_SUCCESS: 'FETCH_LOG_IN_USER_SUCCESS',
  FETCH_LOG_IN_USER_FAILED: 'FETCH_LOG_IN_USER_FAILED',
  FETCH_LOG_OUT_USER_SUCCESS: 'FETCH_LOG_OUT_USER_SUCCESS',
  FETCH_ACTION_START: 'FETCH_ACTION_START',
  FETCH_ACTION_END: 'FETCH_ACTION_END',
  FETCH_REGISTERED_SUCCESSFULLY: 'FETCH_REGISTERED_SUCCESSFULLY',
  FETCH_REMOVE_USER: 'FETCH_REMOVE_USER',
};
