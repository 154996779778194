import React from 'react';

import AccountRequestRow from '../AccountRequestRow/AccountRequestRow';

import './AccountRequestTable.scss';

const AccountRequestsTable = ({ userList }) => {
  return userList.length ? (
    <div className="table" data-testid="account-request--table">
      <div className="table__row table__header">
        <div className="table__cell date">Date</div>
        <div className="table__cell id">ID</div>
        <div className="table__cell first-name">Name</div>
        <div className="table__cell email">E-mail</div>
        <div className="table__cell company">Entity</div>
        <div className="table__cell buttons"></div>
      </div>
      <div className="table__body">
        {userList?.map((user, index) => (
          <AccountRequestRow user={user} key={index} />
        ))}
      </div>
    </div>
  ) : (
    <h4>No Account Requests</h4>
  );
};

export default AccountRequestsTable;
