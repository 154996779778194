import { ALL_USERS_ACTION_TYPES } from './users.types';

export const INITIAL_STATE = {
  users: [],
  requests: [],
  selectedUser: undefined,
  total: 0,
  loading: false,
  createdUserSuccessfully: false,
};

export const usersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_USERS_ACTION_TYPES.FETCH_ALL_USERS_START:
      return { ...state, total: 0, users: [], loading: true };
    case ALL_USERS_ACTION_TYPES.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: payload.content,
        total: payload.totalElements,
      };
    case ALL_USERS_ACTION_TYPES.FETCH_ACCOUNT_REQUESTS_SUCCESS:
      return { ...state, requests: payload };
    case ALL_USERS_ACTION_TYPES.FETCH_SELECTED_USER_START:
      return { ...state, selectedUser: undefined, loading: true };
    case ALL_USERS_ACTION_TYPES.FETCH_SELECTED_USER_SUCCESS:
      return { ...state, selectedUser: payload };
    case ALL_USERS_ACTION_TYPES.FETCH_UPDATED_ALL_USERS:
      return { ...state, users: payload };
    case ALL_USERS_ACTION_TYPES.FETCH_CREATED_USER_SUCCESSFULLY:
      return { ...state, createdUserSuccessfully: true };
    case ALL_USERS_ACTION_TYPES.FETCH_ACTION_START:
      return { ...state, loading: true, createdUserSuccessfully: false };
    case ALL_USERS_ACTION_TYPES.FETCH_ACTION_END:
      return { ...state, loading: false };
    default:
      return state;
  }
};
