import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { isAdmin } from '../../store/currentUser/currentUser.selector';

const AdminOutlet = () => {
  const admin = useSelector(isAdmin);

  return admin ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default AdminOutlet;
