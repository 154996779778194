import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { categories } from '../../../store/restaurants/restaurants.selector';
import { createCategoriesAsync } from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Form from '../../molecules/Form/Form';

import './categoryForm.scss';

const CategoryForm = () => {
  const dispatch = useDispatch();
  const allCategories = useSelector(categories);

  const handleSubmit = (data) => {
    dispatch(createCategoriesAsync(allCategories, data));
  };

  return (
    <div className="p-category-form">
      <Form onSubmit={handleSubmit} title="Add Category">
        <Input
          id="name"
          name="name"
          className="long-black"
          placeholder="name"
          required
        />
        <div>
          <Button
            classes="bg--black hover-bg--green"
            text="save"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

export default CategoryForm;
