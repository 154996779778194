const Plus = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 443.77 443.77"
    >
      <g id="Vlatech_Logo">
        <path
          id="Valtech_Logo_00000059285499995098246850000001237636214874862734_"
          d="M332.83,214.49l-93.08,0l65.82-65.82l-10.46-10.46
		l-65.82,65.82l0-93.09l-14.79,0l0,93.09l-65.82-65.82l-10.46,10.46l65.82,65.82l-93.09,0l0,14.79l93.09,0l-65.82,65.82l10.46,10.46
		l65.82-65.82l0,93.09h14.79l0-93.09l65.82,65.82l10.46-10.46l-65.82-65.82l93.08,0V214.49z M221.88,229.28
		c-4.08,0-7.4-3.31-7.4-7.4c0-4.08,3.31-7.4,7.4-7.4c4.08,0,7.4,3.31,7.4,7.4C229.28,225.97,225.97,229.28,221.88,229.28"
        />
      </g>
    </svg>
  );
};

export default Plus;
