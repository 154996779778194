import './button.scss';

const Button = ({
  text = 'Button',
  icon,
  hoverIcon,
  hoverText,
  classes,
  onClick,
  testID = 'button-element',
  type = 'button',
  ...props
}) => {
  const defaultHoverText = hoverText ? hoverText : text;

  let formattedIcon = '';
  let formattedHoverIcon = '';

  if (icon) formattedIcon = 'icon--' + icon;
  if (hoverIcon) formattedHoverIcon = 'hover-icon--' + hoverIcon;

  const styling = [formattedIcon, formattedHoverIcon, classes].join(' ');
  const formattedStyling = styling.replace(/\s+/g, ' ');

  return (
    <button
      data-testid={testID}
      onClick={onClick}
      className={`button ${formattedStyling}`}
      data-text={text}
      data-hover-text={defaultHoverText}
      type={type}
      {...props}
    />
  );
};

export default Button;
