import axios from 'axios';

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem('persist:root'));
  const accessToken = localStorage.getItem('access_token');

  if (user && accessToken) {
    return { Authorization: 'Bearer ' + accessToken };
  }
  return {};
};

const instance = axios.create({
  baseURL: '/api/',
  headers: authHeader(),
});

export default instance;
