import instance from './setupAxios';

const getAllUsers = () => {
  return instance.get('users');
};

const getAllUsersPaginated = (
  numberOfPage = 0,
  pageSize = 12,
  search,
  company
) => {
  const params = {
    offset: numberOfPage,
    pageSize,
    search: search && search.length >= 3 ? search : '',
    company,
    field: 'id',
    direction: 'DESC',
  };

  return instance.get('users', { params });
};

const getAllUsersRequests = () => {
  return instance.get('requests');
};

const createNewUser = async (firstName, lastName, email, cardId, company) => {
  return await instance.post('users/registerUser', {
    firstName,
    lastName,
    email,
    cardId,
    company,
  });
};

const getUserById = async (id) => {
  return await instance.get(`users/${id}`);
};

const handleUserRequest = async (id, approvalStatus) => {
  return await instance.post('approvalStatus', { id, approvalStatus });
};

const handleUserStatus = async (id, userStatus) => {
  return await instance.post(
    `users/setUserStatus/${id}?userStatus=${userStatus}`,
    { id }
  );
};

const editProfile = async (
  id,
  firstName,
  lastName,
  email,
  cardId,
  userRole,
  company
) => {
  return await instance.put(`users/${id}`, {
    firstName,
    lastName,
    email,
    cardId,
    userRole,
    company,
  });
};

const updateProfile = async (firstName, lastName, email, cardId) => {
  const response = await instance.put(`users/updateProfile`, {
    firstName,
    lastName,
    email,
    cardId,
  });
  return response.data;
};

const deleteUser = async (id) => {
  return await instance.delete(`users/delete/${id}`);
};

const addImage = async (id, image) => {
  return await instance.post(
    `image/user/${id}`,
    {
      image,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const editImage = async (user_id, image_id, image) => {
  return await instance.put(
    `image/${image_id}/user/${user_id}`,
    {
      image,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const deleteImage = async (id) => {
  return await instance.delete(`image/${id}`);
};

const setPassword = async (token, password) => {
  return await instance.post('users/setPassword', { token, password });
};

const resetPassword = async (email) => {
  return await instance.post('users/resetPassword', { email });
};

const UserService = {
  getAllUsersPaginated,
  getAllUsers,
  createNewUser,
  getUserById,
  getAllUsersRequests,
  handleUserRequest,
  handleUserStatus,
  editProfile,
  updateProfile,
  deleteUser,
  setPassword,
  resetPassword,
  addImage,
  editImage,
  deleteImage,
};

export default UserService;
