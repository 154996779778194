import React from 'react';
import { Link } from 'react-router-dom';

import errorImage from '../../../svg/error-404.svg';
import Button from '../../atoms/Button/Button';

import './errorComponent.scss';

const ErrorComponent = ({ errorType, errorTitle, errorDesc }) => {
  if (errorType) {
    return (
      <div className={`m-error-component m-error-component--${errorType}`}>
        <div className="hero-container">
          {errorType === 404 && (
            <img
              src={errorImage}
              className="hero-container__content img"
              alt={`${errorType}: ${errorTitle}`}
            />
          )}
          {errorType === 500 && (
            <h1 className="hero-container__content text">500</h1>
          )}
        </div>
        <div className="error-message">
          <h2 className="error-title">{errorTitle}</h2>
          <p className="error-description">{errorDesc}</p>
        </div>
        <Link to="/dashboard">
          <Button
            icon="arrow-left"
            hoverText="go back"
            classes="bg--white hover-bg--black"
          />
        </Link>
      </div>
    );
  }
};

export default ErrorComponent;
