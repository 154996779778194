import React, { useEffect, useState } from 'react';

import {
  IconArrowLeft,
  IconArrowRight,
  IconDoubleArrowLeft,
  IconDoubleArrowRight,
} from '../Icons';

import './pagination.scss';

const Pagination = ({
  itemsPerPage = 12,
  numbersOfItems,
  onPageChange,
  currentPageParam = 0,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(numbersOfItems / itemsPerPage);
  const pagesPerPaginationGroup = 4;

  useEffect(() => {
    if (currentPageParam !== currentPage - 1) {
      setCurrentPage(currentPageParam + 1);
    }
  }, [currentPageParam]);
  // Don't show pagination if there is one page or items per page is less than 1
  if (numbersOfItems <= itemsPerPage || itemsPerPage < 1) return;

  const goToPageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber - 1);
  };

  const firstPageButton = (
    <span
      data-testid="first-page"
      className={
        pagesPerPaginationGroup < currentPage
          ? undefined
          : 'pagination__icon-inactive'
      }
      onClick={() => goToPageNumber(1)}
    >
      <IconDoubleArrowLeft />
    </span>
  );

  const prevPageButton = (
    <span
      data-testid="prev-page"
      className={currentPage > 1 ? undefined : 'pagination__icon-inactive'}
      onClick={() => goToPageNumber(currentPage - 1)}
    >
      <IconArrowLeft />
    </span>
  );

  //The getPaginationGroup function is used to show the group of page numbers in the pagination.
  // In our case, as we have pagesPerPaginationGroup to 4, so we will show 4 page numbers at a time to the user.
  const paginationGroup = () => {
    //Calculates the starting point that will be used to show the subsequent page numbers.
    // (if start is 0, returns [1, 2, 3, 4], if it is 4, returns [5, 6, 7, 8] etc..)
    const start =
      Math.floor((currentPage - 1) / pagesPerPaginationGroup) *
      pagesPerPaginationGroup;
    const remainingPages = totalPages - start;
    const remainingPagesPerPagination =
      remainingPages < pagesPerPaginationGroup
        ? remainingPages
        : pagesPerPaginationGroup;

    const getPaginationGroup = new Array(remainingPagesPerPagination)
      .fill()
      .map((_, index) => {
        const item = start + index + 1;
        return (
          <li
            key={index}
            onClick={() => goToPageNumber(item)}
            className={
              currentPage === item ? 'pagination__item--active' : undefined
            }
          >
            {item}
          </li>
        );
      });

    return (
      <ul className="pagination__items-container">{getPaginationGroup}</ul>
    );
  };

  const nextPageButton = (
    <span
      data-testid="next-page"
      className={
        totalPages > currentPage ? undefined : 'pagination__icon-inactive'
      }
      onClick={() => goToPageNumber(currentPage + 1)}
    >
      <IconArrowRight />
    </span>
  );

  const lastPageButton = () => {
    const remainingPages =
      totalPages -
      (totalPages % pagesPerPaginationGroup || pagesPerPaginationGroup);
    return (
      <span
        data-testid="last-page"
        className={
          currentPage - 1 < remainingPages
            ? undefined
            : 'pagination__icon-inactive'
        }
        onClick={() => goToPageNumber(totalPages)}
      >
        <IconDoubleArrowRight />
      </span>
    );
  };

  return (
    <div className="pagination" data-testid="pagination-element">
      {firstPageButton}
      {prevPageButton}
      {paginationGroup()}
      {nextPageButton}
      {lastPageButton()}
    </div>
  );
};

export default Pagination;
