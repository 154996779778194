import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { currentUser } from '../../store/currentUser/currentUser.selector';

const PrivateOutlet = () => {
  const user = useSelector(currentUser);

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateOutlet;
