const Logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g id="icon-logout" transform="translate(-419 -839)">
      <path
        id="Path_1131"
        data-name="Path 1131"
        d="M441.063,847a14.063,14.063,0,1,1-9.944,4.119A14.063,14.063,0,0,1,441.063,847Zm0,0"
        transform="translate(-7.063 -7.063)"
        fill="#fff"
      />
      <path
        id="Path_1132"
        data-name="Path 1132"
        d="M435.837,861.791a14.062,14.062,0,0,1,11.724-14.781c-.464-.016-.934-.011-1.408.02a14.063,14.063,0,1,0,1.82,28.066c.474-.031.94-.086,1.4-.162a14.062,14.062,0,0,1-13.535-13.143Zm0,0"
        transform="translate(-12.359 -7.063)"
        fill="#e2e2e2"
      />
      <path
        id="Path_1133"
        data-name="Path 1133"
        d="M449,854a15,15,0,1,1-15-15,15.017,15.017,0,0,1,15,15Zm-28.125,0A13.125,13.125,0,1,0,434,840.875,13.139,13.139,0,0,0,420.875,854Zm0,0"
      />
      <g
        id="Login_Icon"
        data-name="Login Icon"
        transform="translate(427.387 848.113)"
      >
        <path
          id="Path_1095"
          data-name="Path 1095"
          d="M495.753,849.329V848.26a6.411,6.411,0,0,0-6.411-6.411h-.712V839L484,843.808l4.63,4.808v-2.849h1.38a6.412,6.412,0,0,1,5.734,3.544Zm0,0"
          transform="translate(-483.466 -838.466)"
          fill="#33cafb"
        />
        <path
          id="Path_1096"
          data-name="Path 1096"
          d="M484.347,841.849l-1.163-.078.45-1.106,1.6-1.665v2.849h.712a6.411,6.411,0,0,1,6.411,6.411v1.068l-.009-.018a6.391,6.391,0,0,0-1.687-2.1C490.163,844.17,484.858,841.828,484.347,841.849Z"
          transform="translate(-480.073 -838.466)"
          fill="#00bdfa"
        />
        <path
          id="Path_1097"
          data-name="Path 1097"
          d="M488.288,842.389a.477.477,0,0,0,.117-.014.525.525,0,0,0,.416-.51V840.8a6.953,6.953,0,0,0-6.945-6.945H481.7v-2.315a.534.534,0,0,0-.919-.371l-4.63,4.808a.535.535,0,0,0,0,.742l4.63,4.808a.534.534,0,0,0,.919-.371v-2.315h.846a5.844,5.844,0,0,1,5.256,3.248.552.552,0,0,0,.487.3Zm-6.412-7.469a5.887,5.887,0,0,1,5.832,5.144,6.907,6.907,0,0,0-5.164-2.294h-1.38a.535.535,0,0,0-.534.534v1.524l-3.354-3.483,3.354-3.483v1.525a.535.535,0,0,0,.534.534Zm0,0"
          transform="translate(-476 -831.002)"
        />
      </g>
    </g>
  </svg>
);

export default Logout;
