import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { REGEX_EMAIL } from '../../../constants/regex-patterns';
import { resetPasswordAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Form from '../../molecules/Form/Form';

import './forgotPassword.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="p-forgot-password">
      <div className="p-forgot-password__container">
        <Form
          title="Enter your email"
          onSubmit={({ email }) =>
            dispatch(resetPasswordAsync(navigate, email))
          }
        >
          <Input
            className="standard"
            id="email"
            label="email"
            name="email"
            required
            type="email"
            validation={{
              pattern: REGEX_EMAIL,
              patternMessage: INVALID_INPUT.EMAIL,
            }}
            title={INVALID_INPUT.EMAIL}
            autoComplete="email"
          />
          <div>
            <Button classes="bg--green" text="confirm" type="submit" />
            <Button
              onClick={() => navigate('/')}
              classes="bg--red hover-bg--black small"
              hoverIcon="close"
              icon="close"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
