const Show = () => (
  <svg
    data-testid="icon-show"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 16 12"
  >
    <g id="icon-show" transform="translate(0 -2)">
      <path
        id="Path_803"
        data-name="Path 803"
        d="M.667,8S3.333,2.667,8,2.667,15.333,8,15.333,8,12.667,13.333,8,13.333.667,8,.667,8Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
      <path
        id="Path_804"
        data-name="Path 804"
        d="M10,8A2,2,0,1,1,8,6,2,2,0,0,1,10,8Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
      />
    </g>
  </svg>
);

export default Show;
