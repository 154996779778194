import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  filterMainMenuItemsByCategoryAndDate,
  filterMainMenuItemsByDate,
} from '../MenuItemForm/filterFunctions';

import { categories } from '../../../store/restaurants/restaurants.selector';
import OrderItem from '../../molecules/OrderItem/OrderItem';

const Step3 = ({ allItems, orderDate }) => {
  const [allMainMeals, setAllMainMeals] = useState(
    filterMainMenuItemsByDate(allItems, orderDate)
  );
  const mealCategories = useSelector(categories);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const filteredCategories = mealCategories.filter((category) =>
    allItems.some((item) => item.categoryIds.includes(category.id))
  );

  const handleCategoryClick = (categoryId) => {
    if (categoryId === 0) {
      setSelectedCategory(0);
      setAllMainMeals(filterMainMenuItemsByDate(allItems, orderDate));
    } else {
      setAllMainMeals(
        filterMainMenuItemsByCategoryAndDate(allItems, categoryId, orderDate)
      );
      setSelectedCategory(categoryId);
    }
  };

  return (
    <>
      <h4 className="p-order__heading">Please choose a main dish!</h4>
      {allMainMeals.length > 0 ? (
        <div className="row row__large p-order__menu-wrapper">
          <div className="p-order__categories">
            <h4
              className={!selectedCategory ? 'active' : ''}
              onClick={() => handleCategoryClick(0)}
            >
              All
            </h4>
            {filteredCategories.map((category) => {
              return (
                <h4
                  key={category.id}
                  className={selectedCategory === category.id ? 'active' : ''}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.name}
                </h4>
              );
            })}
          </div>
          <div className="p-order__foods">
            <OrderItem items={allMainMeals} type="main" />
          </div>
        </div>
      ) : (
        <h4>No menu items found. Please choose another restaurant.</h4>
      )}
    </>
  );
};

export default Step3;
