export function handleExtraItemSelection(currentItems, newItem) {
  return currentItems.includes(newItem)
    ? currentItems.filter((item) => item !== newItem)
    : [...currentItems, newItem];
}

export function handleDateFormatting(dateString) {
  let dateComponents = dateString.split('/');

  let day = dateComponents[0];
  let month = dateComponents[1];
  let year = dateComponents[2];

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}
