import OrderCalendar from '../../molecules/OrderCalendar/OrderCalendar';

const Step1 = ({ currentDate, setCurrentDate }) => (
  <>
    <h4 className="p-order__heading">
      Please choose the date you are ordering for!
    </h4>
    <OrderCalendar currentDate={currentDate} setCurrentDate={setCurrentDate} />
  </>
);

export default Step1;
