import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideNotification } from '../../../store/notification/notification.action';
import { notification } from '../../../store/notification/notification.selector';

import './notification.scss';

const notificationVariants = {
  initial: {
    opacity: 0,
    y: 50,
    scale: 0.2,
    transition: { duration: 0.1 },
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: { ease: 'easeOut', duration: 0.15 },
  },
  hover: { scale: 1.05, transition: { duration: 0.1 } },
};

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notification);

  useEffect(() => {
    notifications.forEach(({ id, timeout }) => {
      setTimeout(() => {
        dispatch(hideNotification(id)());
      }, timeout);
    });
  }, [notifications]); // eslint-disable-line

  return (
    <div data-testid="notification-element" className={'a-overlay'}>
      <ul>
        <AnimatePresence initial={false}>
          {notifications.map(({ id, title, message, status }, index) => {
            return (
              <motion.li
                key={index}
                variants={notificationVariants}
                whileHover="hover"
                initial="initial"
                animate="animate"
                exit="exit"
                role="alert"
                className={`a-overlay__popup a-overlay--${status}`}
              >
                <h6 className="a-overlay__popup-title">{title}</h6>
                {message && (
                  <p className="a-overlay__popup-message">{message}</p>
                )}
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default Notification;
