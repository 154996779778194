const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 18.696"
    {...props}
  >
    <g id="icon-edit" transform="translate(-427.004 -849.798)">
      <path
        id="Path_1154"
        data-name="Path 1154"
        d="M567.431,861.226l-2.777,2.786-3.255-3.255,2.786-2.777a1.371,1.371,0,0,1,1.949,0l1.3,1.3a1.371,1.371,0,0,1,0,1.949Zm0,0"
        transform="translate(-122.808 -7.105)"
        fill="#fff"
      />
      <path
        id="Path_1155"
        data-name="Path 1155"
        d="M563.007,861.677l2.786-2.777a1.335,1.335,0,0,1,.872-.387l-.532-.532a1.371,1.371,0,0,0-1.949,0l-2.786,2.777,3.255,3.255.344-.346Zm0,0"
        transform="translate(-122.808 -7.105)"
        fill="#e2e2e2"
      />
      <path
        id="Path_1156"
        data-name="Path 1156"
        d="M449.152,897.763l-9.894,9.894-4.258,1,1-4.258,9.894-9.894Zm0,0"
        transform="translate(-7.307 -40.855)"
        fill="#33cafb"
      />
      <path
        id="Path_1157"
        data-name="Path 1157"
        d="M437.612,905.322l9.55-9.55-1.264-1.264L436,904.4l-1,4.258,1.933-.455Zm0,0"
        transform="translate(-7.307 -40.855)"
        fill="#00bdfa"
      />
      <rect
        id="Rectangle_1539"
        data-name="Rectangle 1539"
        width="12.414"
        height="1.379"
        transform="translate(434.59 867.115)"
      />
      <path
        id="Path_1158"
        data-name="Path 1158"
        d="M427.693,868.494a.69.69,0,0,1-.671-.848l1-4.253a.682.682,0,0,1,.184-.329l12.68-12.68a2.119,2.119,0,0,1,2.926,0l1.3,1.3a2.069,2.069,0,0,1,0,2.926l-12.68,12.679a.691.691,0,0,1-.329.184l-4.253,1a.674.674,0,0,1-.159.019Zm1.629-4.594-.7,2.978,2.977-.7,12.541-12.541a.688.688,0,0,0,0-.975l-1.3-1.3a.707.707,0,0,0-.975,0Zm0,0"
      />
      <path
        id="Path_1159"
        data-name="Path 1159"
        d="M555.742,889.85l.975-.975,3.251,3.251-.975.975Zm0,0"
        transform="translate(-117.64 -35.708)"
      />
    </g>
  </svg>
);

export default Edit;
