import { combineReducers } from 'redux';

import { currentUserReducer } from './currentUser/currentUser.reducer';
import { notificationReducer } from './notification/notification.reducer';
import { ordersReducer } from './orders/orders.reducer';
import { restaurantsReducer } from './restaurants/restaurants.reducer';
import { usersReducer } from './users/users.reducer';

export const rootReducer = combineReducers({
  users: usersReducer,
  currentUser: currentUserReducer,
  restaurants: restaurantsReducer,
  notifications: notificationReducer,
  orders: ordersReducer,
});
