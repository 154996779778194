import instance from './setupAxios';

export const getAllUserOrders = async () => {
    return await instance.get('order/userOrders');
};

export const getAllUserOrdersPaginated = (numberOfPage = 0, pageSize = 12, dateFrom, dateTo, restaurantId,userId, company, status ) => {
    const params = {
        dateFrom,
        dateTo,
        userId,
        restaurantId,
        company,
        status,
        offset: numberOfPage,
        pageSize,
        field: 'id',
        direction: 'DESC',
    };

    return instance.get('order/userOrders', {params});
};

export const getCurrentUserOrders = async (userId) => {
    return await instance.get(`order/myOrders`);
};

export const createOrder = async (order) => {
    return await instance.post('order', order);
};

export const cancelOrder = async (userOrderId) => {
    return await instance.post(`order/userOrder/${userOrderId}/cancel`);
};

const OrderService = {
    createOrder,
    cancelOrder,
    getAllUserOrders,
    getCurrentUserOrders,
    getAllUserOrdersPaginated,
};

export default OrderService;
