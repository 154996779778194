import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { cancelUserOrder } from '../../../store/orders/orders.thunk';
import Button from '../../atoms/Button/Button';
import Modal from '../../molecules/Modal/Modal';

import './table.scss';

const Table = ({ tableHeaders, tableData, isOrderTable = false }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(-1);

  const cancelOrder = () => {
    dispatch(cancelUserOrder(orderIdToCancel)).then((isCancelled) => {
      setShowModal(false);
      if (isCancelled) {
        document.getElementById('order_' + orderIdToCancel).remove();
      }
    });
  };

  const displayConfirmationDialog = (userOrderId) => {
    setOrderIdToCancel(userOrderId);
    setShowModal(true);
  };

  return (
    <div>
      <table className="m-gourmet-table">
        <thead className="m-gourmet-table--header">
          <tr>
            {tableHeaders.map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className="m-gourmet-table--body">
          {tableData.length >= 1 ? (
            tableData.map((dataArray, idx) => (
              <tr key={idx} id={'order_' + dataArray[1]}>
                {dataArray.map((value, idx) => (
                  <td key={idx}>
                    <span className="m-gourmet-table--body--cell-header">
                      {tableHeaders[idx]}:
                    </span>
                    {value}
                  </td>
                ))}
                {isOrderTable && (
                  <>
                    {dataArray[dataArray.length - 1] === 'PENDING' ? (
                      <td>
                        <Button
                          classes="bg--blue slim suqare small"
                          text="Cancel"
                          onClick={() =>
                            displayConfirmationDialog(dataArray[1])
                          }
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </>
                )}
              </tr>
            ))
          ) : (
            <h5>No orders to display</h5>
          )}
        </tbody>
      </table>
      {showModal && (
        <div className="p-user__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title={`The order will be cancelled. Are you sure?`}
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={cancelOrder}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Table;
