import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../Modal/Modal';

import { isAdmin } from '../../../store/currentUser/currentUser.selector';
import { restaurants } from '../../../store/restaurants/restaurants.selector';
import { restaurantStatusAsync } from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Image';

import './restaurantItem.scss';

const RestaurantItem = ({ image, name, id, status }) => {
  const allRestaurants = useSelector(restaurants);
  const admin = useSelector(isAdmin);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const restaurantStatusHandler = () =>
    dispatch(restaurantStatusAsync(id, allRestaurants)).then(
      setShowModal(false)
    );

  const textAvailable = 'open for orders';
  const textUnavailable = 'unavailable now';

  return (
    <div className="m-restaurant" data-testid="restaurant-item">
      <Image className="medium m-restaurant__image" src={image} alt={name} />
      <h5
        className={`m-restaurant__title${
          status === 'ACTIVE' ? '' : ' m-restaurant__title--color'
        }`}
        data-testid="restaurant-item__title"
      >
        {name}
      </h5>
      <div className="m-restaurant__action-wrapper">
        <Link to={`/restaurant/${id}/`}>
          <Button
            text="view"
            classes="bg--blue hover-bg--black slim small square"
          />
        </Link>
        <div
          onClick={admin ? () => setShowModal(true) : null}
          className={`status-bar ${status === 'ACTIVE' ? 'active' : ''}`}
        >
          <span className="indicator" />
          <span
            className="text"
            data-text-available={textAvailable}
            data-text-unavailable={textUnavailable}
          />
        </div>
      </div>
      {showModal && (
        <div className="p-user__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title={`The restaurant will be set to ${
              status === 'ACTIVE' ? textUnavailable : textAvailable
            }. Are you sure?`}
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={restaurantStatusHandler}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default RestaurantItem;
