import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { limitForOrders } from '../../../store/restaurants/restaurants.selector';
import { getLimitForOrdersAsync } from '../../../store/restaurants/restaurants.thunk';
import Calendar from '../../atoms/Calendar/Calendar';

const OrderCalendar = ({ currentDate, setCurrentDate }) => {
  const dispatch = useDispatch();
  const limitDays = useSelector(limitForOrders);
  useEffect(() => {
    dispatch(getLimitForOrdersAsync());
  }, []); // eslint-disable-line
  return (
    <Calendar
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
      limitDays={limitDays}
    />
  );
};
export default OrderCalendar;
