const X = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 13 16"
  >
    <text
      id="_"
      data-name="✖"
      transform="translate(0 13)"
      fill="#fff"
      fontSize="16"
      fontFamily="ZapfDingbatsITC, Zapf Dingbats"
      letterSpacing="0.15em"
    >
      <tspan x="0" y="0">
        ✖
      </tspan>
    </text>
  </svg>
);

export default X;
