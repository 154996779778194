import axios from 'axios';

import instance from './setupAxios';

const register = async (firstName, lastName, email, cardId, company) => {
  return await instance.post('register', {
    firstName,
    lastName,
    email,
    cardId,
    company,
  });
};

const login = async (username, password) => {
  const response = await instance.post('login', {
    username,
    password,
  });
  localStorage.setItem('access_token', response.headers['authorization']);
  return response.data;
};

const logout = async () => {
  const response = await instance.post('logout', {});
  localStorage.removeItem('access_token');
  return response.data;
};

const refreshToken = async () => {
  const response = await axios.post(
    '/api/refresh',
    {},
    { withCredentials: true }
  );
  localStorage.setItem('access_token', response.headers['authorization']);
};

const confirmEmail = async (token) => {
  return await instance.post('confirmEmail', { token });
};

const AuthService = {
  register,
  login,
  logout,
  refreshToken,
  confirmEmail,
};

export default AuthService;
