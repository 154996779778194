const Logo = () => (
  <svg
    width="50"
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
  >
    <defs></defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        id="Valtech_Logo"
        data-name="Valtech Logo"
        fill="#fff"
        strokeWidth="0"
        d="M60,28h-25.17s17.8-17.8,17.8-17.8l-2.83-2.83-17.8,17.8V0s-4,0-4,0v25.17S10.2,7.37,10.2,7.37l-2.83,2.83,17.8,17.8H0s0,4,0,4h25.17S7.37,49.8,7.37,49.8l2.83,2.83,17.8-17.8v25.17s4,0,4,0v-25.17s17.8,17.8,17.8,17.8l2.83-2.83-17.8-17.8h25.17s0-4,0-4ZM30,32c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2"
      />
    </g>
  </svg>
);

export default Logo;
