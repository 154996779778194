import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isAdmin } from '../../../store/currentUser/currentUser.selector';
import { fetchAllUsers } from '../../../store/users/users.selector';
import { userStatusAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Image';
import Modal from '../../molecules/Modal/Modal';

import './userItem.scss';

const UserItem = ({ username, email, company, image, userStatus, id }) => {
  const allUsers = useSelector(fetchAllUsers);
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState('');
  const userStatusHandler = () => dispatch(userStatusAsync(id, allUsers));
  const textUserActivate = 'activate user';
  const textUserDeactivate = 'deactivate user';
  const admin = useSelector(isAdmin);
  const [showModal, setShowModal] = useState(false);

  const deactivateUser = () => {
    userStatusHandler(id, allUsers);
    setShowModal(false);
  };

  useEffect(() => {
    if (image) {
      fetch(`/api/image/data/${image.id}`)
        .then((response) => response.blob())
        .then((blob) => {
          setProfileImage(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [image]); // eslint-disable-line

  return (
    <article
      data-testid="user-element"
      className={`m-card m-card--${
        userStatus === 'ACTIVE' ? 'activated' : 'deactivated'
      }`}
    >
      <div className="m-card__content">
        <div className="m-card__info">
          <h5 className="m-card__username">{username}</h5>
          <p className="m-card__email">{email}</p>
          <p className="m-card__company">{company}</p>
        </div>
        <div className="m-card__image">
          <Image
            className="small"
            alt={`Headshot of ${username}`}
            src={profileImage}
          />
        </div>
      </div>
      <div className="m-card__button">
        <Link to={`/user-profile/${id}/`}>
          <Button
            testID="open-user-element"
            text="open"
            classes="bg--blue hover-bg--black square slim small"
          />
        </Link>
      </div>
      <div
        data-testid="deactivate-user-element"
        onClick={admin ? () => setShowModal(true) : null}
        className={`status-bar ${userStatus === 'ACTIVE' ? 'active' : ''}`}
      >
        <span
          className="text"
          data-text-activate={textUserActivate}
          data-text-deactivate={textUserDeactivate}
        />
      </div>
      {showModal && (
        <div className="p-user__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title={`The user will be set to ${
              userStatus === 'ACTIVE' ? 'inactive' : 'active'
            }. Are you sure?`}
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={deactivateUser}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </article>
  );
};

export default UserItem;
