import { createAction } from '@reduxjs/toolkit';

import { RESTAURANTS_ACTION_TYPES } from './restaurants.types';

export const fetchRestaurantsStart = () => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_START);
};

export const fetchRestaurantsSuccess = (restaurants) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_SUCCESS,
    () => ({ payload: restaurants })
  );
};

export const fetchSelectedRestaurantSuccess = (restaurant) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_RESTAURANT_SUCCESS,
    () => {
      return { payload: restaurant };
    }
  );
};

export const removeSelectedRestaurant = createAction(
  RESTAURANTS_ACTION_TYPES.REMOVE_SELECTED_RESTAURANT
);

export const fetchCategoriesSuccess = (categories) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS, () => {
    return { payload: categories };
  });
};

export const fetchLocationsSuccess = (locations) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_LOCATIONS_SUCCESS, () => {
    return { payload: locations };
  });
};

export const successfullyCreatedMenuItem = () => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_CREATED_MENU_ITEM_SUCCESSFULLY
  );
};

export const successfullyCreatedCategory = () => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_CREATED_CATEGORIES_SUCCESSFULLY
  );
};

export const fetchMenuItemsSuccess = (menuItems) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_MENU_ITEMS_SUCCESS, () => {
    return { payload: menuItems };
  });
};

export const fetchUpdatedMenuItems = (menuItems) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_UPDATED_ALL_MENU_ITEMS,
    () => {
      return { payload: menuItems };
    }
  );
};

export const fetchUpdatedCategories = (categories) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_UPDATED_ALL_CATEGORIES,
    () => {
      return { payload: categories };
    }
  );
};

export const fetchMenusSuccess = (menus) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_MENUS_SUCCESS, () => {
    return { payload: menus };
  });
};

export const fetchSelectedMenuItem = (menuItem) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_MENU_ITEM, () => {
    return { payload: menuItem };
  });
};

export const fetchSelectedMenuId = (menuId) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_SELECTED_MENU_ID, () => {
    return { payload: menuId };
  });
};

export const fetchRestaurantsForDate = (restaurants) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_FOR_DATE,
    () => {
      return { payload: restaurants };
    }
  );
};

export const fetchLimitForOrders = (days) => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_LIMIT_FOR_ORDERS, () => {
    return { payload: days };
  });
};

export const fetchRestaurantsEnd = () => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_END);
};

export const fetchTermsAndConditionsStatusStart = () => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_START);
};

export const fetchTermsAndConditionsStatusSuccess = (status) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_SUCCESS,
    () => {
      return { payload: status };
    }
  );
};

export const fetchTermsAndConditionsStatusEnd = () => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_END
  );
};

export const acceptTermsAndConditionsStart = () => {
  return createAction(RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_START);
};

export const acceptTermsAndConditionsSuccess = (status) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_SUCCESS,
    () => {
      return { payload: status };
    }
  );
};

export const acceptTermsAndConditionsEnd = () => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_TERMS_AND_CONDITIONS_STATUS_END
  );
};
