export const validDateTo = (dateFrom, selectedDate) => {
  let dateFromObj = new Date(dateFrom);
  let selectedDateObj = new Date(selectedDate);
  return dateFromObj > selectedDateObj;
};

export const validDateFrom = (dateTo, selectedDate) => {
  let dateToObj = new Date(dateTo);
  let selectedDateObj = new Date(selectedDate);
  return dateToObj < selectedDateObj;
};
