const PlacesToEat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g id="icon-places-to-eat" transform="translate(-310 -38)">
      <path
        id="Path_1119"
        data-name="Path 1119"
        d="M96,152.889v20.625a2.507,2.507,0,0,0,2.5,2.5h23.125a2.507,2.507,0,0,0,2.5-2.5V152.889Z"
        transform="translate(214.938 -108.951)"
        fill="#fff"
      />
      <g
        id="Group_304"
        data-name="Group 304"
        transform="translate(310.938 38.938)"
      >
        <path
          id="Path_1120"
          data-name="Path 1120"
          d="M96,152.889v5.937a3.437,3.437,0,1,0,6.875,0v-.312a3.75,3.75,0,1,0,7.5,0,3.451,3.451,0,1,0,6.875,0v.312a3.437,3.437,0,1,0,6.875,0v-5.937Z"
          transform="translate(-96 -147.889)"
          fill="#33cafb"
        />
        <path
          id="Path_1121"
          data-name="Path 1121"
          d="M182.736,318.708v-6.875a2.5,2.5,0,0,0-2.5-2.5H169.611a2.5,2.5,0,0,0-2.5,2.5v6.875Z"
          transform="translate(-160.861 -290.583)"
          fill="#33cafb"
        />
        <path
          id="Path_1122"
          data-name="Path 1122"
          d="M96,101l2.15-4.313A1.254,1.254,0,0,1,99.275,96H120.85a1.254,1.254,0,0,1,1.125.687l2.15,4.313Z"
          transform="translate(-96 -96)"
          fill="#33cafb"
        />
      </g>
      <path
        id="Path_1123"
        data-name="Path 1123"
        d="M98.812,158.826v-5.937H96v5.937a3.425,3.425,0,0,0,4.844,3.13A3.433,3.433,0,0,1,98.812,158.826Z"
        transform="translate(214.938 -108.951)"
        fill="#00bdfa"
      />
      <path
        id="Path_1124"
        data-name="Path 1124"
        d="M172.424,309.333h-2.812a2.5,2.5,0,0,0-2.5,2.5v6.875h2.813v-6.875A2.5,2.5,0,0,1,172.424,309.333Z"
        transform="translate(150.077 -251.646)"
        fill="#00bdfa"
      />
      <path
        id="Path_1125"
        data-name="Path 1125"
        d="M102.087,96H99.275a1.254,1.254,0,0,0-1.125.687L96,101h2.812l2.15-4.313A1.254,1.254,0,0,1,102.087,96Z"
        transform="translate(214.938 -57.063)"
        fill="#00bdfa"
      />
      <path
        id="Path_1126"
        data-name="Path 1126"
        d="M98.812,257.833v-11.25L96,245.333v12.5a2.507,2.507,0,0,0,2.5,2.5h2.812A2.507,2.507,0,0,1,98.812,257.833Z"
        transform="translate(214.938 -193.271)"
        fill="#e2e2e2"
      />
      <path
        id="Path_1127"
        data-name="Path 1127"
        d="M110.958,163.2a4.367,4.367,0,0,1-3.482-1.73,4.194,4.194,0,0,1-6.861-.105,4.685,4.685,0,0,1-7.39.06,4.373,4.373,0,0,1-7.891-2.6v-5.937h1.875v5.937a2.5,2.5,0,1,0,5,0v-2.187a.937.937,0,1,1,1.875,0v1.875a2.812,2.812,0,1,0,5.625,0v-1.875a.937.937,0,1,1,1.875,0v1.875a2.517,2.517,0,1,0,5,0v-1.875a.937.937,0,1,1,1.875,0v2.187a2.5,2.5,0,1,0,5,0v-5.937h1.875v5.937A4.38,4.38,0,0,1,110.958,163.2Z"
        transform="translate(224.667 -108.951)"
      />
      <path
        id="Path_1128"
        data-name="Path 1128"
        d="M173.944,308.979h-1.875V302.1a1.565,1.565,0,0,0-1.563-1.563H159.882a1.565,1.565,0,0,0-1.563,1.563v6.875h-1.875V302.1a3.44,3.44,0,0,1,3.438-3.437h10.625a3.44,3.44,0,0,1,3.438,3.438Z"
        transform="translate(159.806 -241.917)"
      />
      <path
        id="Path_1129"
        data-name="Path 1129"
        d="M96,142.222h28.125V144.1H96Z"
        transform="translate(214.938 -99.222)"
      />
      <path
        id="Path_1130"
        data-name="Path 1130"
        d="M111.9,115.333H88.77a3.44,3.44,0,0,1-3.437-3.437V91.271a.937.937,0,0,1,.1-.419l2.155-4.309a2.174,2.174,0,0,1,1.956-1.21h21.58a2.172,2.172,0,0,1,1.956,1.21l2.155,4.308a.946.946,0,0,1,.1.42V111.9a3.44,3.44,0,0,1-3.437,3.437ZM87.208,91.492v20.4a1.565,1.565,0,0,0,1.562,1.562H111.9a1.565,1.565,0,0,0,1.562-1.562v-20.4L111.4,87.381a.308.308,0,0,0-.279-.172H89.543a.312.312,0,0,0-.28.171h0Z"
        transform="translate(224.667 -47.333)"
      />
    </g>
  </svg>
);

export default PlacesToEat;
