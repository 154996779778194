import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { confirmEmailAsync } from '../../../store/currentUser/currentUser.thunk';
import logoImage from '../../../svg/valtech-gourmet-logo.svg';
import Button from '../../atoms/Button/Button';
import ConfirmationMark from '../../atoms/Icons/ConfirmationMark';
import ErrorComponent from '../../molecules/ErrorComponent/ErrorComponent';

import './confirmEmail.scss';

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search);

  const token = searchParams.get('token');

  useEffect(() => {
    token && dispatch(confirmEmailAsync(token));
  }, []); //eslint-disable-line

  return (
    <>
      {token ? (
        <div className="confirmation-wrapper">
          <div className="confirmation-container">
            <center>
              <img src={logoImage} alt="Valtech logo" />
              <ConfirmationMark />
              <div>
                <h1>Thank You !</h1>
                <p>
                  Your email has been confirmed, once our administrator approves
                  your account you will receive an email invitation to log in!
                </p>
                <Button text="home" onClick={() => navigate('/')}></Button>
              </div>
            </center>
          </div>
        </div>
      ) : (
        <ErrorComponent
          errorType={500}
          errorTitle="Oops! Not a valid page"
          errorDesc="Sorry, the page does not contain a valid token. Please, make sure you have the correct URL."
        />
      )}
    </>
  );
};

export default ConfirmEmail;
