import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { currentUser } from '../../store/currentUser/currentUser.selector';

const LoginOutlet = () => {
  const user = useSelector(currentUser);

  return user ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default LoginOutlet;
