const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.503"
    height="12.184"
    viewBox="0 0 8.503 12.184"
  >
    <path
      id="arrow-left"
      d="M2.407-2.818V-.152l-8.5-4.7V-7.612l8.5-4.724v2.689L-3.736-6.233Z"
      transform="translate(6.096 12.336)"
    />
  </svg>
);

export default ArrowLeft;
