const Dashboard = () => (
  <svg
    id="icon-dashboard"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      id="Path_1106"
      data-name="Path 1106"
      d="M430.625,848.125H421.25a1.25,1.25,0,0,1-1.25-1.25V841.25a1.25,1.25,0,0,1,1.25-1.25h9.375a1.25,1.25,0,0,1,1.25,1.25v5.625a1.25,1.25,0,0,1-1.25,1.25Zm0,0"
      transform="translate(-419.063 -839.063)"
      fill="#33cafb"
    />
    <path
      id="Path_1107"
      data-name="Path 1107"
      d="M430.625,962.293H421.25a1.25,1.25,0,0,1-1.25-1.25V947.918a1.25,1.25,0,0,1,1.25-1.25h9.375a1.25,1.25,0,0,1,1.25,1.25v13.125a1.25,1.25,0,0,1-1.25,1.25Zm0,0"
      transform="translate(-419.063 -933.23)"
      fill="#f3f3f1"
    />
    <path
      id="Path_1108"
      data-name="Path 1108"
      d="M569.293,1010.668h-9.375a1.25,1.25,0,0,0-1.25,1.25v5.625a1.25,1.25,0,0,0,1.25,1.25h9.375a1.25,1.25,0,0,0,1.25-1.25v-5.625a1.25,1.25,0,0,0-1.25-1.25Zm0,0"
      transform="translate(-541.48 -989.73)"
      fill="#33cafb"
    />
    <path
      id="Path_1109"
      data-name="Path 1109"
      d="M422.813,846.875V841.25a1.25,1.25,0,0,1,1.25-1.25H421.25a1.25,1.25,0,0,0-1.25,1.25v5.625a1.25,1.25,0,0,0,1.25,1.25h2.813a1.25,1.25,0,0,1-1.25-1.25Zm0,0"
      transform="translate(-419.063 -839.063)"
      fill="#00bdfa"
    />
    <path
      id="Path_1110"
      data-name="Path 1110"
      d="M561.481,1017.543v-5.625a1.25,1.25,0,0,1,1.25-1.25h-2.812a1.25,1.25,0,0,0-1.25,1.25v5.625a1.25,1.25,0,0,0,1.25,1.25h2.813a1.25,1.25,0,0,1-1.25-1.25Zm0,0"
      transform="translate(-541.48 -989.73)"
      fill="#00bdfa"
    />
    <path
      id="Path_1111"
      data-name="Path 1111"
      d="M569.293,840h-9.375a1.25,1.25,0,0,0-1.25,1.25v13.125a1.25,1.25,0,0,0,1.25,1.25h9.375a1.25,1.25,0,0,0,1.25-1.25V841.25a1.25,1.25,0,0,0-1.25-1.25Zm0,0"
      transform="translate(-541.48 -839.063)"
      fill="#fff"
    />
    <path
      id="Path_1112"
      data-name="Path 1112"
      d="M430.625,962.293H421.25a1.25,1.25,0,0,1-1.25-1.25V947.918a1.25,1.25,0,0,1,1.25-1.25h9.375a1.25,1.25,0,0,1,1.25,1.25v13.125a1.25,1.25,0,0,1-1.25,1.25Zm0,0"
      transform="translate(-419.063 -933.23)"
      fill="#fff"
    />
    <path
      id="Path_1113"
      data-name="Path 1113"
      d="M422.813,961.043V947.918a1.25,1.25,0,0,1,1.25-1.25H421.25a1.25,1.25,0,0,0-1.25,1.25v13.125a1.25,1.25,0,0,0,1.25,1.25h2.813a1.25,1.25,0,0,1-1.25-1.25Zm0,0"
      transform="translate(-419.063 -933.23)"
      fill="#e2e2e2"
    />
    <path
      id="Path_1114"
      data-name="Path 1114"
      d="M561.481,854.375V841.25a1.25,1.25,0,0,1,1.25-1.25h-2.812a1.25,1.25,0,0,0-1.25,1.25v13.125a1.25,1.25,0,0,0,1.25,1.25h2.813a1.25,1.25,0,0,1-1.25-1.25Zm0,0"
      transform="translate(-541.48 -839.063)"
      fill="#e2e2e2"
    />
    <path
      id="Path_1115"
      data-name="Path 1115"
      d="M423.563,842h-9.375A2.19,2.19,0,0,1,412,839.813v-5.625A2.19,2.19,0,0,1,414.188,832h9.375a2.19,2.19,0,0,1,2.187,2.188v5.625A2.189,2.189,0,0,1,423.563,842Zm-9.375-8.125a.313.313,0,0,0-.313.313v5.625a.313.313,0,0,0,.313.312h9.375a.313.313,0,0,0,.312-.312v-5.625a.313.313,0,0,0-.312-.313Zm0,0"
      transform="translate(-412 -832)"
    />
    <path
      id="Path_1116"
      data-name="Path 1116"
      d="M423.563,956.168h-9.375A2.19,2.19,0,0,1,412,953.98V940.855a2.19,2.19,0,0,1,2.188-2.187h9.375a2.189,2.189,0,0,1,2.187,2.187V953.98a2.19,2.19,0,0,1-2.187,2.188Zm-9.375-15.625a.313.313,0,0,0-.313.312V953.98a.313.313,0,0,0,.313.313h9.375a.313.313,0,0,0,.312-.313V940.855a.313.313,0,0,0-.312-.312Zm0,0"
      transform="translate(-412 -926.168)"
    />
    <path
      id="Path_1117"
      data-name="Path 1117"
      d="M562.23,1012.668h-9.375a2.19,2.19,0,0,1-2.187-2.188v-5.625a2.189,2.189,0,0,1,2.187-2.187h9.375a2.19,2.19,0,0,1,2.188,2.187v5.625a2.19,2.19,0,0,1-2.188,2.188Zm-9.375-8.125a.313.313,0,0,0-.312.312v5.625a.313.313,0,0,0,.312.313h9.375a.313.313,0,0,0,.313-.313v-5.625a.313.313,0,0,0-.313-.312Zm0,0"
      transform="translate(-534.418 -982.668)"
    />
    <path
      id="Path_1118"
      data-name="Path 1118"
      d="M562.23,849.5h-9.375a2.189,2.189,0,0,1-2.187-2.187V834.188A2.19,2.19,0,0,1,552.855,832h9.375a2.19,2.19,0,0,1,2.188,2.188v1.485h-1.875v-1.485a.313.313,0,0,0-.313-.313h-9.375a.313.313,0,0,0-.312.313v13.125a.313.313,0,0,0,.312.312h9.375a.313.313,0,0,0,.313-.312v-9.14h1.875v9.14a2.19,2.19,0,0,1-2.188,2.187Zm0,0"
      transform="translate(-534.418 -832)"
    />
  </svg>
);

export default Dashboard;
