export const RESTAURANTS_ACTION_TYPES = {
  FETCH_RESTAURANTS_START: 'FETCH_RESTAURANTS_START',
  FETCH_RESTAURANTS_SUCCESS: 'FETCH_RESTAURANTS_SUCCESS',
  FETCH_SELECTED_RESTAURANT_SUCCESS: 'FETCH_SELECTED_RESTAURANT_SUCCESS',
  REMOVE_SELECTED_RESTAURANT: 'REMOVE_SELECTED_RESTAURANT',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CREATED_CATEGORIES_SUCCESSFULLY:
    'FETCH_CREATED_CATEGORIES_SUCCESSFULLY',
  FETCH_UPDATED_ALL_CATEGORIES: 'FETCH_UPDATED_ALL_CATEGORIES',
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_CREATED_MENU_ITEM_SUCCESSFULLY: 'FETCH_CREATED_MENU_ITEM_SUCCESSFULLY',
  FETCH_MENU_ITEMS_SUCCESS: 'FETCH_MENU_ITEMS_SUCCESS',
  FETCH_MENUS_SUCCESS: 'FETCH_MENUS_SUCCESS',
  FETCH_UPDATED_ALL_MENU_ITEMS: 'FETCH_UPDATED_ALL_MENU_ITEMS',
  FETCH_SELECTED_MENU_ITEM: 'FETCH_SELECTED_MENU_ITEM',
  FETCH_SELECTED_MENU_ID: 'FETCH_SELECTED_MENU_ID',
  FETCH_RESTAURANTS_FOR_DATE: 'FETCH_RESTAURANTS_FOR_DATE',
  FETCH_LIMIT_FOR_ORDERS: 'FETCH_LIMIT_FOR_ORDERS',
  FETCH_RESTAURANTS_END: 'FETCH_RESTAURANTS_END',
  FETCH_TERMS_AND_CONDITIONS_STATUS_START:
    'FETCH_TERMS_AND_CONDITIONS_STATUS_START',
  FETCH_TERMS_AND_CONDITIONS_STATUS_SUCCESS:
    'FETCH_TERMS_AND_CONDITIONS_STATUS_SUCCESS',
  FETCH_TERMS_AND_CONDITIONS_STATUS_END:
    'FETCH_TERMS_AND_CONDITIONS_STATUS_END',
  ACCEPT_TERMS_AND_CONDITIONS_START: 'ACCEPT_TERMS_AND_CONDITIONS_START',
  ACCEPT_TERMS_AND_CONDITIONS_SUCCESS: 'ACCEPT_TERMS_AND_CONDITIONS_SUCCESS',
  ACCEPT_TERMS_AND_CONDITIONS_END: 'ACCEPT_TERMS_AND_CONDITIONS_END',
};
