import { createAction } from '@reduxjs/toolkit';

import { NOTIFICATION_ACTION_TYPES } from './notification.types';

const collectedNotifications = [];

export const handleNotificationAction = (notification) => {
  collectedNotifications.push({
    id: Math.random().toString(16).slice(2),
    ...notification,
    status: !notification.status ? 'success' : notification.status,
    timeout: notification.timeout || 5000,
  });

  return createAction(NOTIFICATION_ACTION_TYPES.PROCESS, () => {
    return {
      payload: collectedNotifications,
    };
  });
};

export const hideNotification = (id) => {
  const removeIndex = collectedNotifications.findIndex(
    (notification) => notification.id === id
  );
  collectedNotifications.splice(removeIndex, 1);

  return createAction(NOTIFICATION_ACTION_TYPES.PROCESS, () => {
    return {
      payload: collectedNotifications,
    };
  });
};
