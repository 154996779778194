export const ORDERS_ACTION_TYPES = {
  SET_SELECTED_MENU_ITEM: 'FETCH_SELECTED_MENU_ITEM',
  SET_SELECTED_SIDE_MENU_ITEM: 'SET_SELECTED_SIDE_MENU_ITEM',
  SET_SELECTED_EXTRA_MENU_ITEM: 'FETCH_SELECTED_EXTRA_MENU_ITEM',
  SET_ALL_SELECTED_ITEMS: 'SET_ALL_SELECTED_ITEMS',
  SET_FILTERED_ORDERS: 'SET_FILTERED_ORDERS',
  FETCH_ORDERS_START: 'FETCH_ORDERS_START',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_SELECTED_ORDER_SUCCESS: 'FETCH_SELECTED_ORDER_SUCCESS',
  FETCH_CURRENT_USER_ORDERS_SUCCESS: 'FETCH_CURRENT_USER_ORDERS_SUCCESS',
  FETCH_CURRENT_USER_ORDERS: 'FETCH_CURRENT_USER_ORDERS',
  FETCH_CREATED_ORDER_SUCCESSFULLY: 'FETCH_CREATED_ORDER_SUCCESSFULLY',
  FETCH_ORDERS_FOR_DATE: 'FETCH_ORDERS_FOR_DATE',
  FETCH_ORDERS_END: 'FETCH_ORDERS_END',
  CANCEL_ORDER_START: 'CANCEL_ORDER_START',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_END: 'CANCEL_ORDER_END',
  SET_ORDER_COMMENT: 'SET_ORDER_COMMENT',
  SET_SELECTED_ORDER_DATE: 'SET_SELECTED_ORDER_DATE',
  CLEAR_CURRENT_ORDER: 'CLEAR_CURRENT_ORDER',
  SET_SHOULD_SEND_EMAIL: 'SET_SHOULD_SEND_EMAIL',
  SET_TERMS_ACCEPTED: 'SET_TERMS_ACCEPTED',
};
