import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
  selectedRestaurant,
  termsAndConditionsStatus,
} from '../../../store/restaurants/restaurants.selector';
import {
  getRestaurantAsync,
  getTermsAndConditionsStatusAsync,
  acceptTermsAndConditionsAsync,
} from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Modal from '../../molecules/Modal/Modal';

const Step2 = ({ allRestaurants, showTermsModal, setShowTermsModal }) => {
  const dispatch = useDispatch();
  const restaurant = useSelector(selectedRestaurant);
  const termsAndConditionsAccepted = useSelector(termsAndConditionsStatus);
  const locations = restaurant?.locations;
  const [terms, setTerms] = useState(restaurant?.termsAndConditions);
  const { setValue } = useFormContext();

  locations && setValue('locationId', locations[0].id.toString());

  const acceptTermsAndConditions = () => {
    dispatch(acceptTermsAndConditionsAsync(restaurant.id));
    setShowTermsModal(false);
  };

  const handleLocationSelection = (value) => {
    locations && setValue('locationId', locations[0].id.toString());
    dispatch(getRestaurantAsync(value));
    dispatch(getTermsAndConditionsStatusAsync(value));
  };

  useEffect(() => {
    setTerms(restaurant?.termsAndConditions);
    if (!termsAndConditionsAccepted) {
      setShowTermsModal(true);
    }
  }, [restaurant]); // eslint-disable-line

  return (
    <>
      <h4 className="p-order__heading">Please choose a restaurant</h4>
      <SelectInput
        label="restaurant"
        name="restaurantId"
        onChange={(value) => handleLocationSelection(value)}
        options={allRestaurants.map((restaurant) => ({
          value: restaurant.id,
          label: restaurant.name,
        }))}
      />
      {locations && locations.length > 1 && (
        <SelectInput
          label="location"
          name="locationId"
          options={locations.map(({ id, locationName }) => ({
            value: id,
            label: locationName,
          }))}
        />
      )}
      {showTermsModal && (
        <Modal
          showModal={showTermsModal}
          setShowModal={setShowTermsModal}
          hasBorder
          hasBanner
          title="Terms & Conditions"
        >
          <div
            className="terms__content"
            dangerouslySetInnerHTML={{ __html: terms }}
          ></div>
          <div className="terms__buttons">
            <Button text="decline" onClick={() => window.location.reload()} />
            <Button text="accept" onClick={acceptTermsAndConditions} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Step2;
