import './inputDate.scss';

const InputDate = ({ title, name, value, label, onChange }) => {
  return (
    <div className="input-date-container">
      <h5 className="input-date-container__title">{title}</h5>
      <div className="input-date-container__inputs">
        <span className="input-date" data-label={label}>
          <input
            type="date"
            onChange={onChange}
            name={name}
            value={value}
            className="input-date__input"
          />
        </span>
      </div>
    </div>
  );
};

export default InputDate;
