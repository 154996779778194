import MenuItem from '../MenuItem/MenuItem';

const MenuList = ({ mainMenuList, extraMenuList, sideMenuList }) => {
  return (
    <>
      {mainMenuList.length > 0 && <h2>Main</h2>}
      <MenuItem items={mainMenuList} />
      {extraMenuList.length > 0 && <h2>Side</h2>}
      <MenuItem items={extraMenuList} />
      {sideMenuList.length > 0 && <h2>Extra</h2>}
      <MenuItem items={sideMenuList} />
    </>
  );
};

export default MenuList;
